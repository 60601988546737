/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import React, { useEffect } from 'react';

import { getEstimates } from './estimator.actions';
import FullscreenSpinner from 'common/fullscreenSpinner.component';
import ExportPageComponent from './exportPage.component';
import { setPageTitle } from 'modules/layout/layout.actions';

const Export = (props) => {  

    useEffect(() => {
        (async () => {
            props.setPageTitle('Cost Estimator - Export');
            await props.getEstimates();
        })();
    }, []);

    return (
        <>
            {props.estimates && <ExportPageComponent history={props.history}/>}
            {!props.estimates && <FullscreenSpinner/>}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        estimates: state.estimator.get('estimates'),
    };
};

export default withRouter(
    connect(mapStateToProps, {
        getEstimates,
        setPageTitle,
    })(Export),
);
  