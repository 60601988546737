  /* eslint-disable array-callback-return */
  /* eslint-disable react-hooks/exhaustive-deps */
  import {
    setBackButtonPath,
  // TOAST_MESSAGE_SEVERITY_ERROR,
  setPageTitle,
  showToast,
  TOAST_MESSAGE_SEVERITY_SUCCESS,
} from '../layout/layout.actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, MenuItem, TextField, Typography } from '@material-ui/core';
import GooglePlacesAutocompleteComponent from 'common/form/googlePlacesAutocomplete.component';
import BenefitSelections from './benefitSelections.component';
import { getDistance } from 'utilities/googleMapsClient';
import { GET_ESTIMATES_SUCCESS, getEstimates, getFlightPrice, getHomesalePriceByRegion, postEstimate, setEstimations, updateEstimate } from './estimator.actions';
import { COUNTRY_CODES } from 'utilities/countryCodes';
import { getGsaPerDiem } from './estimatorDataRequests';
import { getBenefits } from './constants';
import { getSelectedClientInformation } from 'utilities/authUtils';
import { setBaseMoveInfo } from 'modules/transferees/transferees.actions';

const useStyles = makeStyles((theme) => ({
  textField: {
     maxWidth: '200px',
  },
}));

const CostEstimatorPage = (props) => {  
  const estimateId = props.match.params.estimateId ? parseInt(props.match.params.estimateId) : null;
  const estimateInfo = estimateId ? props.estimates.find((e) => e.estimateId === estimateId) : null;

  const [costEstimate, setCostEstimate] = useState(estimateInfo ? estimateInfo.costEstimate : 'na');
  const [reloPolicy, setReloPolicy] = useState(estimateInfo ? estimateInfo.reloPolicy : null);
  const [hasSpouse] = useState(estimateInfo ? estimateInfo.hasSpouse : false);
  const [youngChildren, setYoungChildren] = useState(estimateInfo ? estimateInfo.youngChildren : 0);
  const [oldChildren, setOldChildren] = useState(estimateInfo ? estimateInfo.oldChildren : 0);
  const [accompanyingAdults, setAccompanyingAdults] = useState(estimateInfo ? estimateInfo.accompanyingAdults : 0);
  const [origin, setOrigin] = useState(estimateInfo ? estimateInfo.origin : '');
  const [destination, setDestination] = useState(estimateInfo ? estimateInfo.destination : '');
  const [transfereeName, setTransfereeName] = useState(estimateInfo ? estimateInfo.transfereeName : null);
  const [distance, setDistance] = useState(0);
  const [isExecutive] = useState(estimateInfo ? estimateInfo.isExecutive : false);

  const [needsDisclaimer, setNeedsDisclaimer] = useState(localStorage.getItem('disclaimer') ? false : true);

  const [benefits, setBenefits] = useState([]);

  const [perDiemRate, setPerDiemRate] = useState(0);
  const [avgHomesalePrice, setAvgHomesalePrice] = useState(0);
  const [avgHomesalePriceOrigin, setAvgHomesalePriceOrigin] = useState(0);
  const [flightCost, setFlightCost] = useState(0);
  const [isUpdatingSelections, setIsUpdatingSelections] = useState(false);
  const [homefindingDays, setHomefindingDays] = useState(3);

  const [savingEstimate, setSavingEstimate] = useState(false);

  const [isDirty, setIsDirty] = useState(false);

  const clientId = getSelectedClientInformation().id;

  const classes = useStyles();

  const saveEstimate = async (savedBenefits, applyTaxGrossUp, topLevelInputOneId, topLevelInputTwoId) => {
      setSavingEstimate(true);

      let estimateBody = {
          estimateId: estimateId,
          clientId: clientId,
          costEstimate: costEstimate,
          reloPolicy: reloPolicy,
          hasSpouse: hasSpouse,
          youngChildren: youngChildren,
          oldChildren: oldChildren,
          accompanyingAdults: accompanyingAdults,
          origin: typeof origin === 'string' ? null : origin,
          destination: typeof destination === 'string' ? null : destination,
          transfereeName: transfereeName,
          distance: distance,
          isExecutive: isExecutive,
          savedBenefits: savedBenefits,
          applyTaxGrossUp: applyTaxGrossUp,
          topLevelInputOneId: topLevelInputOneId,
          topLevelInputTwoId: topLevelInputTwoId,
      }

      const updateEstimateResp = await props.updateEstimate(estimateBody);
      if (updateEstimateResp.response) {
          const getEstimateResp = await props.getEstimates();
          if (getEstimateResp.type === GET_ESTIMATES_SUCCESS) {
              setBenefits(getBenefits(perDiemRate, avgHomesalePrice, avgHomesalePriceOrigin, flightCost, homefindingDays));
              props.showToast(`Successfully saved estimate ${estimateId}.`, { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
          }
      }

      setSavingEstimate(false);
  }

  const exportEstimate = (savedBenefits, applyTaxGrossUp) => {
      saveEstimate(savedBenefits, applyTaxGrossUp);

      props.history.push(`/estimates/${props.match.params.estimateId}/export`);
  }

  const generateAuth = (savedBenefits, benefits, applyTaxGrossUp) => {
      let total = 0;

      benefits.filter((b) => savedBenefits.find((sb) => sb.benefitNumber === b.benefitNumber)).map((benefit) => {
          let currentSavedBenefit = savedBenefits.find((sb) => sb.benefitNumber === benefit.benefitNumber);
  
          benefit.options.map((opt) => {
              let currentSavedOption = currentSavedBenefit.options.find((csbo) => csbo.optionNumber === opt.optionNumber);
  
              if (currentSavedOption) {
                  if (opt.customTotal) {
                      total = total + currentSavedBenefit.cost;
                  } else if (opt.variable) {
                      total = total + (distance * opt.multiplier);
                  } else if (opt.selections && currentSavedOption.selectionId) {
                      total = total + opt.selections.find((selection) => selection.selectionId === currentSavedOption.selectionId).cost;
                  } else {
                      total = total + opt.cost;
                  }
              }
          })
      });
  
      if (applyTaxGrossUp) {
          let reloAllowanceBen = savedBenefits.find((sb) => sb.benefitNumber === 8);
          if (reloAllowanceBen && !reloAllowanceBen.isTaxable) {
              let taxableTotal = total - reloAllowanceBen.cost;
              total = (taxableTotal * 1.6) + reloAllowanceBen.cost;
          } else {
              total = total * 1.6;
          }
      }

      props.setBaseMoveInfo({
        FirstName: transfereeName.split(' ')[0],
        LastName: transfereeName.split(' ')[1],
        OldState: origin.state,
        OldCity: origin.city,
        OldCountry: COUNTRY_CODES.find((cc) => cc['alpha-2'] === origin.country)['alpha-3'],
        NewState: destination.state,
        NewCity: destination.city,
        NewCountry: COUNTRY_CODES.find((cc) => cc['alpha-2'] === destination.country)['alpha-3'],
        Ownership: reloPolicy,
        EstimateId: estimateId,
        EstimateTotal: total,
      });

      props.history.push('/authorizations/new');

    //   props.history.push(`/authorizations/new?firstName=${transfereeName.split(' ')[0]}&lastName=${transfereeName.split(' ')[1]}&oldCountry=${COUNTRY_CODES.find((cc) => cc['alpha-2'] === origin.country)['alpha-3']}&oldState=${origin.state}&oldCity=${origin.city}
    //       &newCountry=${COUNTRY_CODES.find((cc) => cc['alpha-2'] === destination.country)['alpha-3']}&newState=${destination.state}&newCity=${destination.city}&ownership=${reloPolicy}&estimateId=${estimateId}&estimateTotal=${total}`);
  }

  const checkIsDirty = () => {
      if (estimateInfo) {
          let youngChildrenComp = youngChildren ? parseInt(youngChildren) : 0;
          let oldChildrenComp = oldChildren ? parseInt(oldChildren) : 0;
          let accompanyingAdultsComp = accompanyingAdults ? parseInt(accompanyingAdults) : 0;
          if (costEstimate !== estimateInfo.costEstimate || reloPolicy !== estimateInfo.reloPolicy || youngChildrenComp !== estimateInfo.youngChildren || oldChildrenComp !== estimateInfo.oldChildren 
              || accompanyingAdultsComp !== estimateInfo.accompanyingAdults || (origin?.city !== estimateInfo.origin?.city && estimateInfo.origin) || destination?.city !== estimateInfo.destination?.city || transfereeName !== estimateInfo.transfereeName) {
              setIsDirty(true);
          } else {
              setIsDirty(false);
          }
      }
  }

  useEffect(() => {
      (async () => {
          const perDiemSelectionId = estimateInfo?.savedBenefits ? estimateInfo.savedBenefits.find((sb) => sb.benefitNumber === 3)?.options.find((opt) => opt.optionNumber === 15)?.selectionId : null;
          const numDaysBetweenFlights = perDiemSelectionId ? (perDiemSelectionId === 8 ? 1 : (perDiemSelectionId === 9 ? 2 : (perDiemSelectionId === 11 ? 4 : 3))) : 3;
          setHomefindingDays(numDaysBetweenFlights);

          if (destination && destination.city && destination.state) {
              setIsUpdatingSelections(true);

              const gsaResp = await getGsaPerDiem(destination.city, destination.state, '2025');
              const gsaDestinationCity = gsaResp.rates[0].rate[0].city;
              const gsaDestinationState = gsaResp.rates[0].state;

              let regionName = 'United States';
              if (gsaDestinationCity !== null && gsaDestinationState !== null) {
                regionName = `${gsaDestinationCity}, ${gsaDestinationState}`;
              }

              const homesalePriceResp = await props.getHomesalePriceByRegion(regionName);
              if (homesalePriceResp.response) {
                setAvgHomesalePrice(parseFloat(homesalePriceResp.response.averagePrice.replace(/[$,]+/g,"")));
              }

              let currentDate = new Date();
              let fullRate = gsaResp.rates[0].rate[0].months.month.find((rt) => rt.number === (currentDate.getMonth() + 1));
              setPerDiemRate(fullRate.value * 1.2);

              if (origin && origin.city && origin.state) {
                const gsaOriginResp = await getGsaPerDiem(origin.city, origin.state, '2025');
                const gsaOriginCity = gsaOriginResp.rates[0].rate[0].city;
                const gsaOriginState = gsaOriginResp.rates[0].state;

                let originRegionName = 'United States';
                if (gsaOriginCity !== null && gsaOriginState !== null) {
                    originRegionName = `${gsaOriginCity}, ${gsaOriginState}`;
                }

                const homesalePriceOriginResp = await props.getHomesalePriceByRegion(originRegionName);
                if (homesalePriceOriginResp.response) {
                    setAvgHomesalePriceOrigin(parseFloat(homesalePriceOriginResp.response.averagePrice.replace(/[$,]+/g,"")));
                }

                let baseDate = new Date();
                baseDate.setDate(baseDate.getDate() + 14);
                const departureDate = baseDate.toISOString().split('T')[0];
                baseDate.setDate(baseDate.getDate() + numDaysBetweenFlights);
                const returnDate = baseDate.toISOString().split('T')[0];

                const flightCostResp = await props.getFlightPrice({
                    departureCity: gsaOriginCity ? gsaOriginCity : origin.city,
                    departureState: gsaOriginState,
                    destinationCity: gsaDestinationCity ? gsaDestinationCity : destination.city,
                    destinationState: gsaDestinationState,
                    departureDate: departureDate,
                    returnDate: returnDate,
                });

                if (flightCostResp.response && flightCostResp.response.price) {
                    setFlightCost(parseFloat(flightCostResp.response.price.total.replace(/[$,]+/g,"")));
                }

                setIsUpdatingSelections(false);
              } else {
                setIsUpdatingSelections(false);
              }
          }
      })();
  }, [destination, origin]);

  useEffect(() => {
      (async () => {
          if (origin && origin.formattedAddress && destination && destination.formattedAddress) {
              let newDistance = await getDistance(origin, destination);
              setDistance(newDistance.value ? parseFloat(newDistance.value / 1609).toFixed(2) : 0);
              setBenefits(getBenefits(perDiemRate, avgHomesalePrice, avgHomesalePriceOrigin, flightCost, homefindingDays));
          }
      })();
  }, []);

  useEffect(() => {
    setBenefits(getBenefits(perDiemRate, avgHomesalePrice, avgHomesalePriceOrigin, flightCost, homefindingDays));
  }, [perDiemRate, avgHomesalePrice, avgHomesalePriceOrigin, flightCost, homefindingDays]);

  useEffect(() => {
      checkIsDirty();
  }, [costEstimate, reloPolicy, hasSpouse, youngChildren, oldChildren, accompanyingAdults, origin, destination, transfereeName, isExecutive, estimateInfo]);

  const createdDate = estimateInfo && estimateInfo.created ? new Date(estimateInfo.created) : new Date();
  const modifiedDate = estimateInfo && estimateInfo.modified ? new Date(estimateInfo.modified) : new Date();
  const formattedCreated = `${createdDate.getFullYear()}-${((createdDate.getMonth() + 1) <= 9) ? ('0' + (createdDate.getMonth() + 1)) : createdDate.getMonth() + 1}-${(createdDate.getDate() <= 9) ? ('0' + createdDate.getDate()) : createdDate.getDate()}`;
  const formattedModified = `${modifiedDate.getFullYear()}-${((modifiedDate.getMonth() + 1) <= 9) ? ('0' + (modifiedDate.getMonth() + 1)) : modifiedDate.getMonth() + 1}-${(modifiedDate.getDate() <= 9) ? ('0' + modifiedDate.getDate()) : modifiedDate.getDate()}`;

  return (
      <div>
          <Box sx={{ position: 'fixed', zIndex: 4, backgroundColor: 'white', padding: '10px 0px 10px 10px' }}>
              <Box>
                  <Grid container direction="row" >
                      <Grid item xs={2}>
                          <Box sx={{ paddingTop: '10px' }}>
                              <TextField
                                  id="name"
                                  className={classes.textField}
                                  label="Transferee Name"
                                  value={transfereeName}
                                  variant="outlined"
                                  size="small"
                                  onChange={(e) => setTransfereeName(e.target.value)}
                              />
                          </Box>
                      </Grid>
                      <Grid item xs={2}>
                          <Box sx={{ paddingTop: '10px' }}>
                              <TextField
                                  id="costEstimate"
                                  select
                                  label="Cost Estimate Policy"
                                  className={classes.textField}
                                  onChange={(e) => setCostEstimate(e.target.value)}
                                  value={costEstimate}
                                  fullWidth={true}
                                  variant="outlined"
                                  size="small"
                                  disabled={true}
                              >
                                  <MenuItem value={'na'} key={1}>N/A</MenuItem>
                                  <MenuItem value={'tierOne'} key={2}>Tier One</MenuItem>
                                  <MenuItem value={'tierTwo'} key={3}>Tier Two</MenuItem>
                                  <MenuItem value={'custom'} key={4}>Custom</MenuItem>
                              </TextField>
                          </Box>
                      </Grid>
                      <Grid item xs={2}>
                          <Box sx={{ paddingTop: '10px' }}>
                              <TextField
                                  id="costEstimate"
                                  select
                                  label="Relocation Policy"
                                  className={classes.textField}
                                  onChange={(e) => setReloPolicy(e.target.value)}
                                  value={reloPolicy}
                                  fullWidth={true}
                                  variant="outlined"
                                  size="small"
                              >
                                  <MenuItem value={'Homeowner'} key={0}>Homeowner</MenuItem>
                                  <MenuItem value={'Renter'} key={1}>Renter</MenuItem>
                              </TextField>
                          </Box>
                      </Grid>
                      <Grid item xs={2}>
                          <Box sx={{ maxWidth: '200px' }}>
                              <GooglePlacesAutocompleteComponent
                                  initialValue={origin}
                                  value={origin != null ? (origin.formattedAddress ? origin.formattedAddress : origin) : ''}
                                  label={'Origin Location'}
                                  onSelect={async (event, value) => {
                                      setOrigin(value);

                                      if (value && destination && destination.length !== 0) {
                                          // Distance is returned in meters so we need to divide by 1609 to get miles.
                                          let newDistance = await getDistance(value, destination);
                                          setDistance(newDistance.value ? parseFloat(newDistance.value / 1609).toFixed(2) : 0);
                                      }
                                  }}
                                  onInputEdit={async (event) => {
                                      setOrigin(event);
                                  }}
                                  onInputClear={(event) => {
                                      setOrigin('');
                                  }}
                                  // onFocus={onFocus}
                                  // onBlur={onBlur}
                                  // disabled={disabled}
                              />
                          </Box>
                      </Grid>
                      <Grid item xs={2}>
                          <Box sx={{ maxWidth: '200px' }}>
                              <GooglePlacesAutocompleteComponent
                                  initialValue={destination}
                                  value={destination != null ? (destination.formattedAddress ?  destination.formattedAddress : destination) : ''}
                                  label={'Destination Location'}
                                  onSelect={async (event, value) => {
                                      setDestination(value);
                                      if (value && origin && origin.length !== 0) {
                                          // Distance is returned in meters so we need to divide by 1609 to get miles.
                                          let newDistance = await getDistance(origin, value);
                                          setDistance(newDistance.value ? parseFloat(newDistance.value / 1609).toFixed(2) : 0);
                                      }
                                  }}
                                  onInputEdit={(event) => setDestination(event)}
                                  onInputClear={(event) => {
                                      setDestination('');
                                  }}
                                  size="small"
                                  // onFocus={onFocus}
                                  // onBlur={onBlur}
                                  // disabled={disabled}
                              />
                          </Box>
                      </Grid>
                      <Grid item xs={2}>
                          <Box sx={{ paddingTop: '5px' }}>
                              <Typography color="primary">{estimateId ? `Cost Estimate ${estimateId}` : 'New Cost Estimate'}</Typography>
                              <Typography color="secondary">Move Miles: {distance}</Typography>
                          </Box>
                      </Grid>
                      {/* <Grid item xs={2}>
                          <Box sx={{ paddingTop: '0px' }}>
                              <FormControlLabel
                                  control={
                                      <Checkbox
                                          checked={isExecutive}
                                          onChange={(e) => setIsExecutive(!isExecutive)}
                                          size="small"
                                      />
                                  }
                                  label={'Is Executive'}
                              />
                          </Box>
                          <Box sx={{ paddingTop: '0px' }}>
                              <FormControlLabel
                                  control={
                                      <Checkbox
                                          checked={hasSpouse}
                                          onChange={(e) => setHasSpouse(!hasSpouse)}
                                          size="small"
                                      />
                                  }
                                  label={'Traveling with SO'}
                              />
                          </Box>
                      </Grid> */}
                      <Grid item xs={2}>
                          <Box sx={{ paddingTop: '10px' }}>
                              <TextField
                                  id="youngChildren"
                                  className={classes.textField}
                                  label="# Children under 12"
                                  value={youngChildren}
                                  variant="outlined"
                                  type="number"
                                  onChange={(e) => setYoungChildren(e.target.value)}
                                  size="small"
                              />
                          </Box>
                      </Grid>
                      <Grid item xs={2}>
                          <Box sx={{ paddingTop: '10px' }}>
                              <TextField
                                  id="oldChildren"
                                  className={classes.textField}
                                  label="# Children 13 to 17"
                                  value={oldChildren}
                                  variant="outlined"
                                  type="number"
                                  onChange={(e) => setOldChildren(e.target.value)}
                                  size="small"
                              />
                          </Box>
                      </Grid>
                      <Grid item xs={2}>
                          <Box sx={{ paddingTop: '10px' }}>
                              <TextField
                                  id="accompanyingAdults"
                                  className={classes.textField}
                                  label="# Accompanying Adults"
                                  value={accompanyingAdults}
                                  variant="outlined"
                                  type="number"
                                  onChange={(e) => setAccompanyingAdults(e.target.value)}
                                  size="small"
                              />
                          </Box>
                      </Grid>

                      <Grid item xs={2}>
                          <Box sx={{ paddingTop: '10px' }}>
                              <TextField
                                  id="dateCreated"
                                  label="Date Created"
                                  type="date"
                                  value={formattedCreated}
                                  InputLabelProps={{
                                      shrink: true,
                                  }}
                                  InputProps={{
                                      className: classes.input,
                                  }}
                                  placeholder={'yyyy-mm-dd'}
                                  className={classes.textField}
                                  disabled={true}
                                  size="small"
                              />
                          </Box>
                      </Grid>
                      <Grid item xs={2}>
                          <Box sx={{ paddingTop: '10px' }}>
                              <TextField
                                  id="dateModified"
                                  label="Date Modified"
                                  type="date"
                                  value={formattedModified}
                                  InputLabelProps={{
                                      shrink: true,
                                  }}
                                  InputProps={{
                                      className: classes.input,
                                  }}
                                  placeholder={'yyyy-mm-dd'}
                                  className={classes.textField}
                                  disabled={true}
                                  size="small"
                              />
                          </Box>
                      </Grid>
                  </Grid>
              </Box>
          </Box>

          <Box sx={{paddingTop: '175px', marginLeft: '10px', marginRight: '10px'}}>
              <BenefitSelections 
                isHeaderDirty={isDirty} 
                savingEstimate={savingEstimate} 
                reloPolicy={reloPolicy} 
                distance={distance} 
                estimateInfo={estimateInfo} 
                saveEstimate={saveEstimate} 
                exportEstimate={exportEstimate} 
                generateAuth={generateAuth} 
                benefits={benefits} 
                estimateId={estimateId}
                isUpdatingSelections={isUpdatingSelections}
              />
          </Box>

          <Dialog
              open={needsDisclaimer}
              onClose={() => { localStorage.setItem('disclaimer', 'approved'); setNeedsDisclaimer(false); }}
              maxWidth="md"
              fullWidth>
              <DialogTitle>
                  Disclaimer
              </DialogTitle>
              <DialogContent classes={{ root: classes.content }}>
                  <Box>
                      <Typography variant="subtitle1" color="secondary">
                          The prices contributing to the cost calculator are purely estimates based on similar moves. The actual costs could vary significantly from the estimated totals.
                      </Typography>
                  </Box>
              </DialogContent>
              <DialogActions>
                  <Grid container justify="flex-end">
                      <Box sx={{padding: '12px'}}>
                          <Button variant="outlined" size="large" color="primary" onClick={() => { localStorage.setItem('disclaimer', 'approved'); setNeedsDisclaimer(false); }} disabled={false}>Confirm</Button>
                      </Box>
                  </Grid>
              </DialogActions>
          </Dialog>
      </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authorizationSearchResults: state.transferees.get('authorizationSearchResults'),
    estimates: state.estimator.get('estimates')
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setPageTitle,
    showToast,
    setEstimations,
    getEstimates,
    postEstimate,
    updateEstimate,
    setBackButtonPath,
    getHomesalePriceByRegion,
    getFlightPrice,
    setBaseMoveInfo,
  })(CostEstimatorPage),
);
