import Immutable from 'immutable';

import { GET_ESTIMATES_SUCCESS, SET_NEW_ESTIMATION } from './estimator.actions';

const initialState = Immutable.Map({
    estimates: null
//   estimates: [
//     {
//         estimateId: 1,
//         transfereeName: 'New Accountant',
//         origin: {
//             city: 'Madison',
//             country: 'US',
//             formattedAddress: 'Madison, WI, USA',
//             state: 'WI'
//         },
//         destination: {
//             city: 'Chicago',
//             country: 'US',
//             formattedAddress: 'Chicago, IL, USA',
//             state: 'IL'
//         },
//         isExecutive: false,
//         reloPolicy: 'Renter',
//         costEstimate: 'na',
//         youngChildren: 1,
//         oldChildren: 0,
//         accompanyingAdults: 0,
//         savedBenefits: [{
//             benefitNumber: 0,
//             options: [{optionNumber: 1}, {optionNumber: 2}]
//         }, {
//             benefitNumber: 2,
//             options: [{optionNumber: 11, selectionId: 2}, {optionNumber: 12}, {optionNumber: 13}]
//         }, {
//             benefitNumber: 8,
//             options: [{optionNumber: 27}],
//             cost: 5000,
//             isTaxable: true,
//         }],
//         applyTaxGrossUp: true
//     },
//     {
//         estimateId: 2,
//         transfereeName: 'John Smith',
//         origin: {
//             city: 'Cleveland',
//             country: 'US',
//             formattedAddress: 'Cleveland, OH, USA',
//             state: 'OH'
//         },
//         destination: {
//             city: 'Pittsburgh',
//             country: 'US',
//             formattedAddress: 'Pittsburgh, PA, USA',
//             state: 'PA'
//         },
//         isExecutive: true,
//         reloPolicy: 'Homeowner',
//         savedBenefits: [{
//             id: 0,
//             options: [{optionId: 1}, {optionId: 2}]
//         }, {
//             id: 2,
//             options: [{optionId: 11, selectionId: 2}, {optionId: 12}, {optionId: 13}]
//         }]
//     },
//     {
//         estimateId: 3,
//         transfereeName: 'Jane Johnson',
//         origin: {
//             city: 'Boulder',
//             country: 'US',
//             formattedAddress: 'Boulder, CO, USA',
//             state: 'CO'
//         },
//         destination: {
//             city: 'Boise',
//             country: 'US',
//             formattedAddress: 'Boise, ID, USA',
//             state: 'ID'
//         },
//         isExecutive: false,
//         reloPolicy: 'Homeowner',
//         savedBenefits: [{
//             id: 0,
//             options: [{optionId: 1}, {optionId: 2}]
//         }, {
//             id: 2,
//             options: [{optionId: 11, selectionId: 2}, {optionId: 12}, {optionId: 13}]
//         }]
//     },
//     {
//         estimateId: 4,
//         transfereeName: 'Jim Butler',
//         origin: {
//             city: 'St Paul',
//             country: 'US',
//             formattedAddress: 'St Paul, MN, USA',
//             state: 'MN'
//         },
//         destination: {
//             city: 'New York',
//             country: 'US',
//             formattedAddress: 'New York, NY, USA',
//             state: 'NY'
//         },
//         isExecutive: true,
//         reloPolicy: 'Renter',
//         savedBenefits: [{
//             id: 0,
//             options: [{optionId: 1}, {optionId: 2}]
//         }, {
//             id: 2,
//             options: [{optionId: 11, selectionId: 2}, {optionId: 12}, {optionId: 13}]
//         }]
//     },
//     {
//         estimateId: 5,
//         transfereeName: 'Tom Riddle',
//         origin: {
//             city: 'Fargo',
//             country: 'US',
//             formattedAddress: 'Fargo, ND, USA',
//             state: 'ND'
//         },
//         destination: {
//             city: 'Seattle',
//             country: 'US',
//             formattedAddress: 'Seattle, WA, USA',
//             state: 'WA'
//         },
//         isExecutive: true,
//         reloPolicy: 'Renter',
//         savedBenefits: [{
//             id: 0,
//             options: [{optionId: 1}, {optionId: 2}]
//         }, {
//             id: 2,
//             options: [{optionId: 11, selectionId: 2}, {optionId: 12}, {optionId: 13}]
//         }]
//     },
//   ]
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_NEW_ESTIMATION:
      return state.set('estimates', action.payload);

    case GET_ESTIMATES_SUCCESS:
      return state.set('estimates', action.response);

    default:
      return state;
  }
}
