/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Paper, TextField, Typography, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CostUnit, displayCost } from '../../utilities/currencyFormatter';
import LogoSpinner from '../../common/logoSpinner.component';
import SelectionsCostBar from './selectionsCostBar.component';

import { setEstimations } from './estimator.actions';

import CloseIcon from '@material-ui/icons/Close';

import Spinner from 'common/logoSpinner.component';
import NavigationPrompt from 'react-router-navigation-prompt';
import ConfirmModal from 'common/confirmModal.component';
import { setBackButtonPath, showToast, TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/layout.actions';

import { v4 as uuidv4 } from 'uuid';
import { detectIntent } from 'modules/policies/bot/dialogFlow.actions';
import { DETECT_INTENT_FAILURE } from 'modules/policies/bot/dialogFlow.types';

const useStyles = makeStyles((theme) => ({
  button: {
    width: 80,
    marginRight: '5px'
  },
  longButton: {
    width: 125
  },
  moveGuideHeader:{
    height: '75px',
    borderBottom: 2,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.divider,
    color: '#7F7F7F',
    borderRadius: `${theme.spacing(1)} ${theme.spacing(1)} 0 0`,
  },
  progressBar: {
    width: '100%',
  },
  optionRow: {
    borderTopStyle: 'solid',
    borderTopWidth: '1px',
    borderTopColor: theme.palette.divider,
    minHeight: theme.spacing(3),
  },
  benefitImage: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '60px',
  },
  benefitImageSkipped: {
    filter: 'grayscale(100%)',
  },
  optionQuantityText:{
    fontStyle: 'italic',
    fontSize: '0.6rem',
  },
  benefitListContainer: {
    overflow: 'auto',
  },
  benefitListWrapper: {
    overflow: 'auto',
  },
  loadingOverlay :{
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 3,
    backgroundColor: 'rgba(0,0,0,0.3)', // controls black overlay opacity
    display: 'flex',
  },
  benefitSelected: {
    boxShadow: '0px 0px 12px 1px rgba(90,154,156,0.5)',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textField: {
    maxWidth: '200px',
  },
}));



const BenefitSelections = (props) => {
  const { benefits, estimateId, isUpdatingSelections, savingEstimate, isHeaderDirty } = props;
  const [expandedBenefitId, setExpandedBenefitId] = useState(null);
  const [savedBenefits, setSavedBenefits] = useState(props.estimateInfo && props.estimateInfo.savedBenefits ? props.estimateInfo.savedBenefits : []);

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedBenefit, setSelectedBenefit] = useState(null);
  const [selectedBenefitOptions, setSelectedBenefitOptions] = useState(null);
  const [topLevelInputOneId, setTopLevelInputOneId] = useState(props.estimateInfo && props.estimateInfo.topLevelInputOneId ? props.estimateInfo.topLevelInputOneId : null);
  const [topLevelInputTwoId, setTopLevelInputTwoId] = useState(props.estimateInfo && props.estimateInfo.topLevelInputTwoId ? props.estimateInfo.topLevelInputTwoId : null);
  const [isUpdatingBenefitPrice, setIsUpdatingBenefitPrice] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [applyTaxGrossUp, setApplyTaxGrossUp] = useState(props.estimateInfo && props.estimateInfo.applyTaxGrossUp ? true : false);

  const [customTotal, setCustomTotal] = useState((props.estimateInfo && props.estimateInfo.savedBenefits && props.estimateInfo.savedBenefits.find((sb) => sb.benefitNumber === 8)) ? props.estimateInfo.savedBenefits.find((sb) => sb.benefitNumber === 8).cost : 3000);
  const [customTotalIsTaxable, setCustomTotalIsTaxable] = useState((props.estimateInfo && props.estimateInfo.savedBenefits && props.estimateInfo.savedBenefits.find((sb) => sb.benefitNumber === 8)) ? props.estimateInfo.savedBenefits.find((sb) => sb.benefitNumber === 8).isTaxable : false);

  const [isBenefitListDirty, setIsBenefitListDirty] = useState(false);

  const [sessionId] = useState(uuidv4());
  const [showHintModal, setShowHintModal] = useState(false);
  const [hintText, setHintText] = useState(null);
  const [hintHeader, setHintHeader] = useState(null);

  const [showGeneralHintModal, setShowGeneralHintModal] = useState(false);
  const [selectedHintOption, setSelectedHintOption] = useState(null);
  const [selectedHintText, setSelectedHintText] = useState(null);

  const classes = useStyles();

  const setBenOptionSelection = (checked, opt) => {
    let tempSelectedBenefitOptions = [...selectedBenefitOptions];
    if (checked) {
      tempSelectedBenefitOptions.push({ optionNumber: opt.optionNumber, selectionId: opt.selections ? opt.selections[0].selectionId : undefined});
    } else {
      tempSelectedBenefitOptions = tempSelectedBenefitOptions.filter((tsb) => tsb.optionNumber !== opt.optionNumber);
    }
    setSelectedBenefitOptions(tempSelectedBenefitOptions);
  }

  const setBenOptionDropdown = (opt, val) => {
    let tempSelectedBenefitOptions = [...selectedBenefitOptions];
    let optIndex = tempSelectedBenefitOptions.findIndex((tsbo) => tsbo.optionNumber === opt.optionNumber);
    if (optIndex != null) {
      tempSelectedBenefitOptions[optIndex].selectionId = val;
    } else {
      tempSelectedBenefitOptions.push({ optionNumber: opt.optionNumber, selectionId: val});
    }
    setSelectedBenefitOptions(tempSelectedBenefitOptions);
  }

  const getOptionCost = (opt, selectedOption) => {
    if (!opt) return 0;

    // const fullOptionInfo = selectedBenefit.options.find((sbo) => sbo.optionNumber === opt.optionNumber);
    if (opt.variable) {
      return props.distance * opt.multiplier;
    } else if (selectedOption && selectedOption.selectionId) {
      return opt.selections.find((selection) => selection.selectionId === selectedOption.selectionId).cost;
    } else {
      if (opt.optionNumber === 17) {
        const perDiemSelectionId = (selectedBenefit && savedBenefits && savedBenefits.find((sb) => selectedBenefit.benefitNumber === sb.benefitNumber)) ? savedBenefits.find((sb) => selectedBenefit.benefitNumber === sb.benefitNumber).options.find((opt) => opt.optionNumber === 15)?.selectionId : null;
        const numDaysBetweenFlights = perDiemSelectionId ? (perDiemSelectionId === 8 ? 1 : (perDiemSelectionId === 9 ? 2 : (perDiemSelectionId === 11 ? 4 : 3))) : 3;
        return 98.89 * numDaysBetweenFlights;
      } else if (opt.optionNumber === 1) {
        if (topLevelInputOneId && topLevelInputTwoId) {
          return Math.random() * 1000;
        } else {
          return 0;
        }
      } else if (opt.optionNumber === 6) {
        if (topLevelInputOneId && topLevelInputTwoId) {
          return Math.random() * 1000;
        } else {
          return 0;
        }
      } else {
        return opt.cost;
      }
    }
  }

  const enableBenefit = () => {
    setIsLoading(true);

    setTimeout(() => {}, 2000);

    let tempSavedBenefits = [...savedBenefits];
    if (!selectedBenefit.customTotal) {
      tempSavedBenefits.push({ benefitNumber: selectedBenefit.benefitNumber, estimateId: estimateId, options: selectedBenefitOptions});
    } else {
      tempSavedBenefits.push({ benefitNumber: selectedBenefit.benefitNumber, estimateId: estimateId, options: selectedBenefitOptions, cost: Number(customTotal), isTaxable: customTotalIsTaxable});
    }
    setSavedBenefits(tempSavedBenefits);

    setIsLoading(false);
    setShowUpdateModal(false);
  }

  const updateBenefit = () => {
    setIsLoading(true);

    let tempSavedBenefits = [...savedBenefits];
    let tempSavedBenefitIndex = tempSavedBenefits.findIndex((tsb) => tsb.benefitNumber === selectedBenefit.benefitNumber);
    if (!selectedBenefit.customTotal) {
      tempSavedBenefits[tempSavedBenefitIndex] = {benefitNumber: selectedBenefit.benefitNumber, id: tempSavedBenefits[tempSavedBenefitIndex].id, estimateId: estimateId, options: selectedBenefitOptions};
    } else {
      tempSavedBenefits[tempSavedBenefitIndex] = {benefitNumber: selectedBenefit.benefitNumber, id: tempSavedBenefits[tempSavedBenefitIndex].id, estimateId: estimateId, options: selectedBenefitOptions, cost: Number(customTotal), isTaxable: customTotalIsTaxable};
    }

    setSavedBenefits(tempSavedBenefits);

    setIsLoading(false);
    setShowUpdateModal(false);
  }

  const disableBenefit = () => {
    setIsLoading(true);

    let tempSavedBenefits = [...savedBenefits];
    tempSavedBenefits = tempSavedBenefits.filter((tsb) => selectedBenefit.benefitNumber !== tsb.benefitNumber);
    setSavedBenefits(tempSavedBenefits);

    setIsLoading(false);
    setShowUpdateModal(false);
  }

  const handleAccordionChange = (benefitId) => (event, isExpanded) => {
    setExpandedBenefitId(isExpanded ? benefitId : false);
  };

  const renderOption = (option, benefit, benIsSelected, optIsSelected) => {
    let optionText = '';
    let savedOption = savedBenefits.find((sb) => sb.benefitNumber === benefit.benefitNumber) ? savedBenefits.find((sb) => sb.benefitNumber === benefit.benefitNumber).options.find((sbo) => sbo.optionNumber === option.optionNumber) : null;
    
    if (option.variable) {
      // optionText = `${option.description} (${props.distance} Miles * $${option.multiplier})`;
      optionText = option.description;
    } else if (option.selections && savedOption) {
      optionText = savedOption ? `${option.description} (${option.selections.find((os) => os.selectionId === savedOption.selectionId).text})` : option.description;
    } else {
      optionText = option.description;
    }

    return (
      <Grid item container direction="row" className={classes.optionRow}>
        <Grid item xs={8} container direction="column">
          <Grid item>
            <Box sx={{display: 'flex', flexDirection: 'row'}}>
              <Typography variant="body2">
                {optionText}
              </Typography>
              {option.helpIntentId && (
                <Box sx={{marginLeft: '5px'}}>
                  <Box sx={{cursor: 'pointer'}} onClick={() => helpModalOpened(option)}>
                    <FontAwesomeIcon color={'primary'} icon={['fas', 'question-circle']} />
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid item container xs={2} justify="flex-end" alignContent="center">
          {!benefit.customTotal && (
            <Typography variant="caption">{displayCost(getOptionCost(option, savedOption), CostUnit.CURRENCY)}</Typography>
          )}
          {benefit.customTotal && (
            <Typography variant="caption">{displayCost(savedBenefits.find((sb) => sb.benefitNumber === benefit.benefitNumber) ? Number(savedBenefits.find((sb) => sb.benefitNumber === benefit.benefitNumber).cost) : 3000, CostUnit.CURRENCY)}</Typography>
          )}
        </Grid>
        <Grid item container xs={2} justify="flex-start" alignContent="center">
          {benIsSelected && optIsSelected && 
            <Box pl={2}>
              <Typography variant="caption" color="secondary"><FontAwesomeIcon icon="check-circle" /> Confirmed</Typography>
            </Box>
          }
          {benIsSelected && !optIsSelected && 
            <Box pl={2}>
              <Typography variant="caption" color="error"><FontAwesomeIcon icon="times-circle" /> Declined</Typography>
            </Box>
          }
        </Grid>
      </Grid>
    )
  };

  const renderBenefitRowAction = (benefit, savedBenefit) => {
    if (savedBenefit) {
        return (
                <Button size="small" variant="outlined" disabled={false} color="primary" onClick={(e) => { setSelectedBenefit(benefit); setSelectedBenefitOptions(savedBenefit.options); setShowUpdateModal(true); }}>
                    Update
                </Button>
        )
    } else {
      let tempBenefitOptions = [];
      benefit.options.map((bo) => tempBenefitOptions.push({ optionNumber: bo.optionNumber, selectionId: bo.defaultSelectionId ? bo.defaultSelectionId: undefined}));
        return (
                <Button size="small" variant="contained" disabled={false} color="primary" onClick={(e) => { setSelectedBenefit(benefit); setSelectedBenefitOptions(tempBenefitOptions); setShowUpdateModal(true); }}>
                    Enable
                </Button>
        )
    }
  };

  const resetBenefits = () => {
    setSavedBenefits([]);
  };

  const helpModalOpened = async (option) => {
    setHintHeader(option.description);
    setShowHintModal(true);

    const action = await props.detectIntent(sessionId, option.helpIntentId, { event: { languageCode: 'en', name: option.helpIntentId }});
    if (action.type === DETECT_INTENT_FAILURE) {
      props.showToast(`Failed to generate benefit option hint.`, { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return;
    } else {
      setHintText(action.response.queryResult.fulfillmentText);
    }
  }

  const generalBenefitHelpSelected = async (benefit) => {
    setShowGeneralHintModal(true);
    setSelectedHintText('');

    // const action = await props.detectIntent(sessionId, defaultOption.helpIntentId, { event: { languageCode: 'en', name: defaultOption.helpIntentId }});
    // if (action.type === DETECT_INTENT_FAILURE) {
    //   props.showToast(`Failed to generate benefit option hint.`, { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    //   return;
    // } else {
    //   setSelectedHintText(action.response.queryResult.fulfillmentText);
    // }
  }

  const changeSelectedBenefitHint = async (option) => {
    setSelectedHintOption(option);
    setSelectedHintText(null);

    const action = await props.detectIntent(sessionId, option.helpIntentId, { event: { languageCode: 'en', name: option.helpIntentId }});
    if (action.type === DETECT_INTENT_FAILURE) {
      props.showToast(`Failed to generate benefit option hint.`, { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return;
    } else {
      setSelectedHintText(action.response.queryResult.fulfillmentText);
    }
  }

  useEffect(() => {
    if (savedBenefits && props.estimateInfo?.savedBenefits) {
      let isDirty = false;

      // Checking if benefits or options were removed
      savedBenefits.map((draftBenefit) => {
        const savedBenefit = props.estimateInfo.savedBenefits.find((esb) => esb.benefitNumber === draftBenefit.benefitNumber);
        if (!savedBenefit) {
          isDirty = true;
        } else {
          draftBenefit.options.map((draftOption) => {
            const savedOption = savedBenefit.options.find((opt) => opt.optionNumber === draftOption.optionNumber);
            if (!savedOption) {
              isDirty = true;
            } else {
              if (draftOption.selectionId !== savedOption.selectionId) {
                isDirty = true;
              }
            }
          })
        }
      })

      // Checking if benefits or options were added
      props.estimateInfo.savedBenefits.map((savedBenefit) => {
        const draftBenefit = savedBenefits.find((sb) => sb.benefitNumber === savedBenefit.benefitNumber);
        if (!draftBenefit) {
          isDirty = true;
        } else {
          savedBenefit.options.map((savedOption) => {
            const draftOption = draftBenefit.options.find((opt) => opt.optionNumber === savedOption.optionNumber);
            if (!draftOption) {
              isDirty = true;
            } else {
              if (draftOption.selectionId !== savedOption.selectionId) {
                isDirty = true;
              }
            }
          })
        }
      })

      // Checking if top level inputs have changed
      if (props.estimateInfo.topLevelInputOneId !== topLevelInputOneId || props.estimateInfo.topLevelInputTwoId !== topLevelInputTwoId) {
        isDirty = true;
      }

      setIsBenefitListDirty(isDirty);
    } 
  }, [savedBenefits, props.estimateInfo?.savedBenefits]);

  useEffect(() => {
    if (topLevelInputOneId && topLevelInputTwoId) {
      setIsUpdatingBenefitPrice(true);
      setTimeout(() => {
        setIsUpdatingBenefitPrice(false);
      }, 2000);
    }
  }, [topLevelInputOneId, topLevelInputTwoId])

  return (
    <Box position="relative">
        <NavigationPrompt when={isHeaderDirty || isBenefitListDirty}> 
            {({ onConfirm, onCancel }) => {
                return (
                    <ConfirmModal
                        cancelText="Cancel"
                        confirmText="Confirm"
                        handleClose={() => { props.setBackButtonPath('/estimates'); onCancel(); }}
                        handleConfirm={onConfirm}
                        titleText="Unsaved Changes"
                        bodyText="You have unsaved changes on this cost estimate. Would you like to leave this page?"
                    />
                );
            }}
        </NavigationPrompt>

        <Fragment>
          {isUpdatingSelections && (
            <Box className={classes.loadingOverlay}>
              <LogoSpinner size="large" />
            </Box>
          )}
          <Grid container direction="column">
            <Grid item>
              <Paper className={classes.moveGuideHeader}>
                <Box p={2.5}>
                  <Grid container direction="row">
                    <Grid item xs={4}>
                      <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Typography variant="h6">Benefit Selections</Typography>
                        <Box sx={{ paddingTop: '0px' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={applyTaxGrossUp}
                                        onChange={(e) => setApplyTaxGrossUp(!applyTaxGrossUp)}
                                        size="small"
                                    />
                                }
                                label={'Apply Tax Gross Up (60%)'}
                            />
                        </Box>
                      </Box>
                      
                    </Grid>
                    <Grid item xs={4} container justify="center">
                      <Box mt={1} width="100%" textAlign="center">
                        <SelectionsCostBar savedBenefits={savedBenefits} benefits={benefits} distance={props.distance} applyTaxGrossUp={applyTaxGrossUp} />
                      </Box>
                    </Grid>
                    <Grid item xs={4} container justify="flex-end" alignItems="flex-start">
                      {!savingEstimate && (
                        <Box sx={{marginTop: '10px'}}>
                          <Button 
                            size="small"
                            color="secondary" 
                            className={classes.button} 
                            onClick={() => resetBenefits()}
                          >
                            Reset
                          </Button>
                          <Button variant="contained" 
                            size="small"
                            color="secondary" 
                            className={classes.button} 
                            onClick={() => props.exportEstimate(savedBenefits, applyTaxGrossUp)}
                          >
                            Export
                          </Button>
                          <Button variant="contained" 
                            size="small"
                            color="secondary" 
                            className={classes.button}
                            disabled={!isHeaderDirty && !isBenefitListDirty}
                            onClick={() => props.saveEstimate(savedBenefits, applyTaxGrossUp, topLevelInputOneId, topLevelInputTwoId)}
                          >
                            Save
                          </Button>
                          <Button variant="contained" 
                            size="small"
                            color="primary" 
                            className={classes.longButton} 
                            onClick={() => props.generateAuth(savedBenefits, benefits, applyTaxGrossUp)}
                          >
                            Generate Auth
                          </Button>
                        </Box>
                      )}
                      {savingEstimate && (
                        <Box sx={{marginTop: '10px'}}>
                          <Spinner/>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
            <Grid item className={classes.benefitListContainer}>
              <Grid container direction="column" wrap="nowrap">
                {
                  benefits.map((benefit) => {
                    let totalBenefitCost = 0;
                    let currentSavedBenefit = savedBenefits.find((sb) => sb.benefitNumber === benefit.benefitNumber);

                    if (benefit.customTotal) {
                      if (currentSavedBenefit) {
                        totalBenefitCost = currentSavedBenefit.cost;
                      } else {
                        totalBenefitCost = 3000;
                      }
                    } else {
                      if (currentSavedBenefit) {
                        totalBenefitCost = benefit.options.filter((opt) => currentSavedBenefit.options.find((csbo) => csbo.optionNumber === opt.optionNumber)).reduce((total, o) => total + getOptionCost(o, currentSavedBenefit.options.find((csbo) => csbo.optionNumber === o.optionNumber)), 0);
                      } else {
                        totalBenefitCost = benefit.options.reduce((total, o) => total + (o.variable ? (props.distance * o.multiplier) : o.cost), 0);
                      }
                    }

                    if (props.reloPolicy && props.reloPolicy === 'Renter' && benefit.isOwnerOnly) return null;

                    if (props.reloPolicy && props.reloPolicy === 'Homeowner' && benefit.isRenterOnly) return null;

                    return (
                      <Accordion 
                        key={benefit.benefitNumber}
                        expanded={expandedBenefitId === benefit.benefitNumber}
                        className={expandedBenefitId === benefit.benefitNumber ? classes.benefitSelected : null}
                        onChange={handleAccordionChange(benefit.benefitNumber)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          // style={{ cursor: !hasSelectedOptions && 'default' }} // inline to ensure override
                        >
                          <Grid container alignItems="center" spacing={1}>
                            <Grid item xs={1} style={{ backgroundImage: `url(${benefit.imageUrl})` }}
                              className={classNames(classes.benefitImage)}
                            />
                            <Grid item xs={11}>
                              <Box mr={0}>
                                <Grid container>
                                  <Grid item xs={9}>
                                    <Typography variant="subtitle1">
                                      {benefit.name}
                                    </Typography>
                                    <Box lineHeight="1">
                                      <Typography variant="caption">
                                        {benefit.secondaryText}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <Box sx={{ paddingTop: '10px'}}>
                                        <Typography variant="subtitle1">{displayCost(totalBenefitCost, CostUnit.CURRENCY)}</Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={1}>
                                    {savedBenefits.find((sb) => sb.benefitNumber === benefit.benefitNumber) && (
                                        <Box sx={{ paddingTop: '15px' }}>
                                            <FontAwesomeIcon icon="check-circle" />
                                        </Box>
                                    )}
                                  </Grid>
                                  <Grid item xs={1}>
                                    <Box sx={{ paddingTop: '10px'}}>
                                        {renderBenefitRowAction(benefit, savedBenefits.find((sb) => sb.benefitNumber === benefit.benefitNumber))}
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails style={{ marginTop: 0 }}>
                          {
                            (benefit.options.length > 0) &&
                              <Grid container>
                                <Grid item xs={1} />
                                <Grid item xs={11}>
                                  <Box mr={4}>
                                    <Grid container direction="column" spacing={4}>
                                      {
                                        benefit.options.map((opt) => renderOption(opt, benefit, savedBenefits.find((sb) => sb.benefitNumber === benefit.benefitNumber), savedBenefits.find((sb) => sb.benefitNumber === benefit.benefitNumber) && savedBenefits.find((sb) => sb.benefitNumber === benefit.benefitNumber).options.find((o) => o.optionNumber === opt.optionNumber)))
                                      }
                                    </Grid>
                                  </Box>
                                </Grid>
                              </Grid>
                          }
                        </AccordionDetails>
                      </Accordion>
                    );
                  })
                }
              </Grid>
            </Grid>
          </Grid>
        </Fragment> 

        {showGeneralHintModal && (
          <Dialog
            open={showGeneralHintModal}
            onClose={() => { setShowGeneralHintModal(false); setSelectedHintOption(null); setSelectedHintText(null); }}
            maxWidth="sm"
            fullWidth>
              <DialogTitle>
                {selectedBenefit.name}
                <IconButton aria-label="close" className={classes.closeButton} onClick={() => { setShowGeneralHintModal(false); setSelectedHintOption(null); setSelectedHintText(null); }}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent classes={{ root: classes.content }}>
                <Box
                  marginY="1px"
                  marginX={0}
                  paddingY={1}
                  paddingX={2}
                  maxWidth="100%"
                  borderRadius="20px"
                  bgcolor={'#EAEAEA'}
                  color={''}
                >
                  {`${selectedBenefit.generalBenefitHelp} Select a benefit option below to learn more about it.`}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row'}}>
                  {selectedBenefit.options.map((sbo) => {
                    return (
                      <Box sx={{ padding: '16px 16px 16px 0px', width: '275px', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        {(!selectedHintOption || (selectedHintOption && sbo.optionNumber !== selectedHintOption.optionNumber)) && (
                          <Button variant="outlined" size="large" color="primary" onClick={() => changeSelectedBenefitHint(sbo)} disabled={false}>{sbo.description}</Button>
                        )}
                        {(selectedHintOption && sbo.optionNumber === selectedHintOption.optionNumber) && (
                          <Button variant="contained" size="large" color="primary" disabled={false}>{sbo.description}</Button>
                        )}
                      </Box>
                    )
                  })}
                </Box>
                {selectedHintText == null && <Spinner/>}
                {selectedHintText != null && selectedHintText.length > 0 && (
                  <Box
                    marginY="1px"
                    marginX={0}
                    paddingY={1}
                    paddingX={2}
                    maxWidth="100%"
                    borderRadius="20px"
                    bgcolor={'#EAEAEA'}
                    color={''}
                  >
                    {selectedHintText}
                  </Box>
                )}
              </DialogContent>
          </Dialog>
        )}

        {showHintModal && (
          <Dialog
            open={showHintModal}
            onClose={() => { setShowHintModal(false); setHintText(null); setHintHeader(null); }}
            maxWidth="sm"
            fullWidth>
              <DialogTitle>
                {hintHeader}
                <IconButton aria-label="close" className={classes.closeButton} onClick={() => { setShowHintModal(false); setHintText(null); setHintHeader(null); }}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent classes={{ root: classes.content }}>
                {!hintText && <Spinner/>}
                {hintText && <Typography variant="subtitle1">{hintText}</Typography>}
              </DialogContent>
          </Dialog>
        )}

        {selectedBenefit && (
          <Dialog
            open={showUpdateModal}
            onClose={() => setShowUpdateModal(false)}
            maxWidth="md"
            fullWidth>
              <DialogTitle>
                {!selectedBenefit.generalBenefitHelp && (
                  <>
                    {selectedBenefit.name}
                  </>
                )}
                {selectedBenefit.generalBenefitHelp && (
                  <Box sx={{ display: 'flex', flexDirection: 'row'}}>
                    <Box>
                      {selectedBenefit.name}
                    </Box>
                    {selectedBenefit.generalBenefitHelp && (
                      <Box sx={{marginLeft: '5px'}}>
                        <Box sx={{cursor: 'pointer'}} onClick={() => generalBenefitHelpSelected(selectedBenefit)}>
                          <FontAwesomeIcon color={'primary'} icon={['fas', 'question-circle']} />
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}
                <IconButton aria-label="close" className={classes.closeButton} onClick={() => setShowUpdateModal(false)}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent classes={{ root: classes.content }}>
                {isUpdatingBenefitPrice && (
                  <Box className={classes.loadingOverlay}>
                    <LogoSpinner size="large" />
                  </Box>
                )}
                {(selectedBenefit.topLevelInputOne || selectedBenefit.topLevelInputTwo) && (
                  <>
                    <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: '20px' }}>
                      {selectedBenefit.topLevelInputOne && (
                        <Box sx={{ marginRight: '10px', minWidth: '200px'}}>
                          <TextField
                              id="topLevelInputOneId"
                              select
                              label={selectedBenefit.topLevelInputOne.name}
                              className={classes.textField}
                              onChange={(e) => { 
                                setTopLevelInputOneId(e.target.value);  
                              }}
                              value={topLevelInputOneId ? topLevelInputOneId : ''}
                              fullWidth={true}
                              variant="outlined"
                              size="small"
                              disabled={false}
                          >
                            {selectedBenefit.topLevelInputOne.options.map((topOpt) => <MenuItem value={topOpt.id} key={topOpt.id}>{topOpt.label}</MenuItem>)}
                          </TextField>
                        </Box>
                      )}
                      {selectedBenefit.topLevelInputTwo && (
                        <Box sx={{ minWidth: '200px'}}>
                          <TextField
                              id="topLevelInputTwoId"
                              select
                              label={selectedBenefit.topLevelInputTwo.name}
                              className={classes.textField}
                              onChange={(e) => { 
                                setTopLevelInputTwoId(e.target.value);  
                              }}
                              value={topLevelInputTwoId ? topLevelInputTwoId : ''}
                              fullWidth={true}
                              variant="outlined"
                              size="small"
                              disabled={false}
                          >
                            {selectedBenefit.topLevelInputTwo.options.map((topOpt) => {
                              if (selectedBenefit.topLevelInputTwo.id === 'numBedrooms' && topLevelInputOneId !== 4 && topOpt.id === 4) {
                                return null;
                              } else {
                                return <MenuItem value={topOpt.id} key={topOpt.id}>{topOpt.label}</MenuItem>;
                              }
                            })}
                          </TextField>
                        </Box>
                      )}
                    </Box>
                    {(topLevelInputOneId == null || topLevelInputTwoId == null) && (
                      <Box sx={{paddingTop: '10px', paddingBottom: '10px'}}>
                        <Typography variant="subtitle1" color="error">The above inputs must be selected for the benefit options to calculate.</Typography>
                      </Box>
                    )}
                  </>
                )}
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                  {selectedBenefit.options.map((opt) => {
                    let selectedOption = selectedBenefitOptions.find((sbo) => sbo.optionNumber === opt.optionNumber);
                    return (
                      <Box sx={{borderTop: '2px solid', backgroundColor: selectedOption ? '#f5f7f7' : undefined}}>
                        <Grid container>
                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectedOption}
                                  disabled={false}
                                  onChange={(e) => setBenOptionSelection(e.target.checked, opt)}
                                />
                              }
                              label={opt.description}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '5px', marginBottom: '5px'}}>
                              {opt.selections && (
                                  <TextField
                                      id="optionSelection"
                                      select
                                      label=""
                                      className={classes.textField}
                                      onChange={(e) => { 
                                        setBenOptionDropdown(opt, e.target.value);  
                                      }}
                                      value={selectedOption ? selectedOption.selectionId : 1}
                                      fullWidth={true}
                                      variant="outlined"
                                      size="small"
                                      disabled={!selectedOption}
                                  >
                                    {opt.selections.map((os) => <MenuItem value={os.selectionId} key={os.selectionId}>{os.text}</MenuItem>)}
                                  </TextField>
                              )}
                              {selectedBenefit.customTotal && (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={customTotalIsTaxable}
                                      disabled={false}
                                      onChange={(e) => setCustomTotalIsTaxable(e.target.checked)}
                                    />
                                  }
                                  label={'Is Taxable'}
                                />
                              )}
                            </Box>
                          </Grid>
                          <Grid item xs={4}>
                            {!selectedBenefit.customTotal && (
                              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '8px'}}>
                                <Typography variant="subtitle1" color={selectedOption ? 'secondary' : undefined}>{displayCost(getOptionCost(opt, selectedOption), CostUnit.CURRENCY)}</Typography>
                              </Box>
                            )}
                            {selectedBenefit.customTotal && (
                              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', paddingTop: '5px', paddingBottom: '5px'}}>
                                <TextField
                                    id="customTotal"
                                    label=""
                                    className={classes.textField}
                                    onChange={(e) => setCustomTotal(e.target.value)}
                                    value={customTotal}
                                    fullWidth={true}
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    disabled={false}
                                    InputProps={{
                                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                />
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    )
                  })}
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', borderTop: '2px solid', paddingTop: '10px'}}>
                  <Box sx={{paddingRight: '10px'}}>
                    <Typography variant="subtitle1">Selected Total: </Typography>
                  </Box>
                  {!selectedBenefit.customTotal && (
                    <Typography variant="subtitle1" color="secondary">{displayCost(selectedBenefitOptions.reduce((total, o) => total + getOptionCost(selectedBenefit.options.find((sbo) => sbo.optionNumber === o.optionNumber), o), 0), CostUnit.CURRENCY)}</Typography>
                  )}
                  {selectedBenefit.customTotal && (
                    <Typography variant="subtitle1" color="secondary">{displayCost(Number(customTotal), CostUnit.CURRENCY)}</Typography>
                  )}
                </Box>
              </DialogContent>
              <DialogActions>
                {savedBenefits.find((sb) => sb.benefitNumber === selectedBenefit.benefitNumber && !isLoading) && (
                  <Grid container justify="flex-end">
                    <Box sx={{padding: '12px'}}>
                      <Button variant="outlined" size="large" color="primary" onClick={() => disableBenefit()} disabled={false}>Disable Benefit</Button>
                    </Box>
                    <Box sx={{padding: '12px'}}>
                      <Button variant="contained" size="large" color="primary" onClick={() => updateBenefit()} disabled={false}>Update Benefit</Button>
                    </Box>
                  </Grid>
                )}
                {!savedBenefits.find((sb) => sb.benefitNumber === selectedBenefit.benefitNumber && !isLoading) && (
                  <Grid container justify="flex-end">
                    <Box sx={{padding: '12px'}}>
                      <Button variant="contained" size="large" color="primary" onClick={() => enableBenefit()} disabled={false}>Enable Benefit</Button>
                    </Box>
                  </Grid>
                )}
                {isLoading && <LogoSpinner />}

              </DialogActions>
          </Dialog>
        )}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    estimates: state.estimator.get('estimates'),
  };
};

export default 
  connect(mapStateToProps, {
    setEstimations,
    setBackButtonPath,
    detectIntent,
    showToast
  })(BenefitSelections)
;