import { Redirect, Route, Switch } from 'react-router-dom';
import React from 'react';

import { cachedWithRouteOnEnter, withRouteOnEnter } from '../../utilities/withRouteOnEnter.component';
import { getSelectedClientInformation } from 'utilities/authUtils';
import AuthorizationEditCreate from '../authorizations/authorizationEditCreate/authorizationEditCreate.component';
import AuthorizationsPage from '../authorizations/authorizations.container';
import BenefitForm from '../benefits/benefitForm.component';
import BenefitsContainer from '../benefits/benefits.container';
import ClientDocumentsGrid from '../documents/clientDocumentsGrid.component';
import ContactsPage from '../contacts/contacts.container';
import DashboardPage from '../dashboard/dashboard.container';
import ExceptionsApproval from '../exceptions/exceptionApproval/pendingException.component';
import ExceptionsPage from '../exceptions/exceptions.container';
import Invoices from 'modules/invoices/Invoices';
import MPCAuthorizations from 'modules/authorizations/MPCAuthorizations';
import PoliciesContainer from '../policies/policies.container';
import PolicyWizardContainer from '../policies/policyWizard.container';
import ReportPage from '../reporting/reportPage.container';
import ReportingPage from '../reporting/reporting.container';
import SubmittedAuthDetails from '../transferees/submittedAuthDetails/submittedAuthDetails.component';
import TransfereesPage from '../transferees/transferees.container';
import Estimates from 'modules/estimator/estimates.container';
import costEstimatorContainer from 'modules/estimator/costEstimator.container';
import exportContainer from 'modules/estimator/export.container';

// import LoginPage from "../login/login.container";
// import ChangePassword from "../authentication/changePassword.container";
// import UserAdmin from "../admin/userAdmin.container";

const AppRoutes = (props) => {
  return (
    <Switch>
      <Route path="/" exact component={withRouteOnEnter(props.onRouteEnter)(DashboardPage)} />
      <Route path="/dashboard" component={withRouteOnEnter(props.onRouteEnter)(DashboardPage)} />
      <Route path="/authorizations" exact component={withRouteOnEnter(props.onRouteEnter)(getSelectedClientInformation().id === '238' ? MPCAuthorizations : AuthorizationsPage)} />
      <Route path="/authorizations/:id" component={withRouteOnEnter(props.onRouteEnter)(AuthorizationEditCreate)} />
      <Route path="/transferees" exact component={withRouteOnEnter(props.onRouteEnter)(TransfereesPage)} />
      <Route path="/transferees/:id" exact component={withRouteOnEnter(props.onRouteEnter)(SubmittedAuthDetails)} />
      <Route path="/exceptions" exact component={withRouteOnEnter(props.onRouteEnter)(ExceptionsPage)} />
      <Route path="/exceptions/submitted/:id" component={withRouteOnEnter(props.onRouteEnter)(ExceptionsApproval)} />
      <Route path="/invoices" component={withRouteOnEnter(props.onRouteEnter)(Invoices)} />
      <Route path="/reporting" exact component={withRouteOnEnter(props.onRouteEnter)(ReportingPage)} />
      <Route path="/reporting/:name" exact component={withRouteOnEnter(props.onRouteEnter)(ReportPage)} />
      <Route path="/reporting/:name/?:query" exact component={withRouteOnEnter(props.onRouteEnter)(ReportPage)} />
      <Route path="/contacts" component={withRouteOnEnter(props.onRouteEnter)(ContactsPage)} />
      <Route path="/benefits" exact component={withRouteOnEnter(props.onRouteEnter)(BenefitsContainer)} />
      <Route path="/benefits/add" component={withRouteOnEnter(props.onRouteEnter)(BenefitForm)} />
      <Route path="/benefits/:id/v/:versionId" component={withRouteOnEnter(props.onRouteEnter)(BenefitForm)} />
      <Route path="/policies" exact component={withRouteOnEnter(props.onRouteEnter)(PoliciesContainer)} />
      <Route path="/policies/:id/v/:versionId" component={cachedWithRouteOnEnter(PolicyWizardContainer, props.onRouteEnter)} />
      <Route path="/policies/add/:id/v/:versionId" component={cachedWithRouteOnEnter(PolicyWizardContainer, props.onRouteEnter)} />
      <Route path="/documents" exact component={withRouteOnEnter(props.onRouteEnter)(ClientDocumentsGrid)} />
      <Route path="/estimates" exact component={withRouteOnEnter(props.onRouteEnter)(Estimates)} />
      <Route path="/estimates/:estimateId" exact component={withRouteOnEnter(props.onRouteEnter)(costEstimatorContainer)} />
      <Route path="/estimates/:estimateId/export" exact component={withRouteOnEnter(props.onRouteEnter)(exportContainer)} />
      <Route path="/estimates/new" exact component={withRouteOnEnter(props.onRouteEnter)(costEstimatorContainer)} />
      {/* <Route path="/login" component={withRouteOnEnter(props.onRouteEnter)(LoginPage)} /> */}
      {/* <Route path="/changepassword" component={withRouteOnEnter(props.onRouteEnter)(ChangePassword)} /> */}
      {/* <Route path="/useradmin" component={withRouteOnEnter(props.onRouteEnter)(UserAdmin)} /> */}
      <Redirect from="*" to="/" />
    </Switch>
  );
};

export default AppRoutes;
