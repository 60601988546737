import Add from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import React, { Component } from 'react';
import TableCell from '@material-ui/core/TableCell';

import { EditCommandCell } from '../../common/tableEditCommandCell';
import { dateFormatter } from '../../utilities/dateFormatter';

import {
  DataTypeProvider,
  EditingState,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';

import {
  Grid,
  PagingPanel,
  Table,
  TableEditColumn,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import { setPageTitle, showToast, TOAST_MESSAGE_SEVERITY_SUCCESS } from 'modules/layout/layout.actions';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import { DELETE_ESTIMATE_SUCCESS, deleteEstimate, getEstimates, postEstimate } from './estimator.actions';
import { getSelectedClientInformation } from 'utilities/authUtils';
import FullscreenSpinner from 'common/fullscreenSpinner.component';
import ConfirmModal from 'common/confirmModal.component';

const DIALOG_CANCEL = 'DIALOG_CANCEL';

const DateTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={dateFormatter} {...props} />
);

const getRowId = (row) => row.AuthorizationID;

class Estimates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: 'estimateId', title: 'Estimate Id' },
        { name: 'transfereeName', title: 'Transferee Name' },
        { name: 'origin', title: 'Origin Location', getCellValue: (row) => (<div>{row.origin && row.origin.formattedAddress ? row.origin.formattedAddress : ''}</div>) },
        { name: 'destination', title: 'Destination Location', getCellValue: (row) => (<div>{row.destination && row.destination.formattedAddress ? row.destination.formattedAddress : ''}</div>) },
        { name: 'isExecutive', title: 'Is Executive', getCellValue: (row) => (<div>{row.isExecutive ? 'Yes' : 'No'}</div>) },
        { name: 'reloPolicy', title: 'Relo Policy' },
      ],
      tableColumnExtensions: [{  }],
      rows: [],
      currentPage: 0,
      pageSize: 5,
      pageSizes: [5, 10, 15],
      dateColumns: ['LastUpdated'],
      vipColumns: ['VIP'],
      sorting: [{ columnName: 'LastUpdated', direction: 'desc' }],
      modal: {
        modalConfirmOpen: false,
        contentText: '',
        type: null,
        selectedRow: null,
      },
      deleteEstimateId: null,
    };

    this.changeSorting = (sorting) => this.setState({ sorting });

    this.TableRow = ({ row, handleEditAuthorization, ...restProps }) => {
      return (
        <Table.Row
          {...restProps}
          onClick={() => this.props.history.push(`/estimates/${row.estimateId}`)}
          style={{
            cursor: 'pointer',
          }}
        />
      );
    };

    this.changeCurrentPage = (currentPage) => this.setState({ currentPage });
    this.changePageSize = (pageSize) => this.setState({ pageSize });
    this.commitChanges = this.commitChanges.bind(this);
    this.cancelAuth = this.cancelAuth.bind(this);
    this.handleModalResponse = this.handleModalResponse.bind(this);
    this.createEstimate = this.createEstimate.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.deleteEstimate = this.deleteEstimate.bind(this);
  }

  async cancelAuth(row) {
    this.setState({ deleteEstimateId: row.row.estimateId });
  }

  async deleteEstimate(estimateId) {
    const deleteResp = await this.props.deleteEstimate(estimateId);
    if (deleteResp.type === DELETE_ESTIMATE_SUCCESS) {
      this.props.showToast(`Successfully deleted estimate ${estimateId}.`, { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
      this.setState({ deleteEstimateId: null });
      await this.props.getEstimates();
    }
  }

  // required for editing state
  commitChanges() {}

  handleModalResponse(response, type, row) {
    switch (type) {
      case DIALOG_CANCEL:
        if (response) {
          // do cancel
          this.props.handleCancelAuthorization(row.row.AuthorizationID);
        } else {
          // do nothing
        }
        break;

      default:
        break;
    }
    this.setState({ modal: { modalConfirmOpen: false } });
  }

  actionsCell = (props) => {
    return (
      <TableCell>
        <EditCommandCell
          id="cancel"
          text="Delete"
          style={{ border: 'none' }}
          onExecute={this.cancelAuth}
          tableRow={props.tableRow}
        >
          {React.Children.toArray(props.children)}
        </EditCommandCell>
      </TableCell>
    );
  };

  createEstimate = async () => {
    const postEstimateResp = await this.props.postEstimate({ clientId: getSelectedClientInformation().id, costEstimate: 'na', youngChildren: 0, oldChildren: 0, accompanyingAdults: 0 })
    if (postEstimateResp.response) {
      this.props.history.push(`/estimates/${postEstimateResp.response.estimateId}`);
    }
  }

  fetchData = async () => {
    await this.props.getEstimates();
  }

  componentDidMount() {
    this.props.setPageTitle('My Estimates');

    this.fetchData();
  }

  render() {
    const { estimates } = this.props;
    const {
      columns,
      pageSize,
      pageSizes,
      currentPage,
      dateColumns,
      sorting,
      tableColumnExtensions,
    } = this.state;

    return (
      <Box sx={{padding: '2rem'}}>

        {this.state.deleteEstimateId && (
          <ConfirmModal
            cancelText="Cancel"
            confirmText="Confirm"
            handleClose={() => { this.setState({ deleteEstimateId: null}) }}
            handleConfirm={() => { this.deleteEstimate(this.state.deleteEstimateId) }}
            titleText="Unsaved Changes"
            bodyText={`Are you sure you would like to delete estimate ${this.state.deleteEstimateId}?`}
          />
        )}

        <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={this.createEstimate}
            style={{ float: 'right', marginTop: '1rem', marginRight: '1rem' }}
        >
            <Add />
            <Hidden mdUp>New</Hidden>
            <Hidden smDown>Add New</Hidden>
        </Button>

        <Paper style={{ padding: '0.5rem' }}>
          <div style={{ padding: '0.3rem', fontSize: '1.25rem' }}>
            Saved Estimates
          </div>
          {!estimates && <FullscreenSpinner/>}
          {estimates && (
            <Grid rows={estimates} columns={columns} getRowId={getRowId}>
              <DateTypeProvider for={dateColumns} />
              <EditingState
                onEditingRowIdsChange={this.changeEditingRowIds}
                onCommitChanges={this.commitChanges}
              />
              <SortingState
                sorting={sorting}
                onSortingChange={this.changeSorting}
              />
              <PagingState
                currentPage={currentPage}
                onCurrentPageChange={this.changeCurrentPage}
                pageSize={pageSize}
                onPageSizeChange={this.changePageSize}
              />
              <IntegratedSorting />
              <IntegratedPaging />
              <Table
                rowComponent={this.TableRow}
                columnExtensions={tableColumnExtensions}
              />
              <TableHeaderRow showSortingControls />
              <TableEditColumn
                style={{ marginRight: '2rem' }}
                cellComponent={this.actionsCell}
              />
              {estimates && estimates.length > 5 && <PagingPanel pageSizes={pageSizes} />}
            </Grid>
          )}
        </Paper>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
    return {
      estimates: state.estimator.get('estimates'),
    };
};
  
export default withRouter(connect(mapStateToProps, {
    setPageTitle,
    getEstimates,
    postEstimate,
    deleteEstimate,
    showToast,
})(Estimates));
