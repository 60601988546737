  /* eslint-disable array-callback-return */
  /* eslint-disable react-hooks/exhaustive-deps */
  import {
    // TOAST_MESSAGE_SEVERITY_ERROR,
    setPageTitle,
    showToast,
  } from '../layout/layout.actions';
  import { connect } from 'react-redux';
  import { withRouter } from 'react-router-dom';
  import React, { useEffect, useState } from 'react';
  
  import { Box, Button, Checkbox, FormControlLabel, Grid, makeStyles, MenuItem, Paper, TextField, Typography } from '@material-ui/core';
import GooglePlacesAutocompleteComponent from 'common/form/googlePlacesAutocomplete.component';
import { getDistance } from 'utilities/googleMapsClient';
import { getFlightPrice, getHomesalePriceByRegion, setEstimations } from './estimator.actions';
import { getBenefits } from './constants';
import SelectionsCostBar from './selectionsCostBar.component';
import { CostUnit, displayCost } from 'utilities/currencyFormatter';
import { getGsaPerDiem } from './estimatorDataRequests';
import LogoSpinner from 'common/logoSpinner.component';

  const useStyles = makeStyles((theme) => ({
    textField: {
       maxWidth: '200px',
    },
    button: {
        width: 125,
        marginRight: '5px'
    },
    longButton: {
        width: 125
    },
    loadingOverlay :{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 3,
        backgroundColor: 'rgba(0,0,0,0.3)', // controls black overlay opacity
        display: 'flex',
    },
  }));
  
  const ExportPage = (props) => {  
    const estimateId = props.match.params.estimateId ? parseInt(props.match.params.estimateId) : null;
    const estimateInfo = estimateId ? props.estimates.find((e) => e.estimateId === estimateId) : null;

    const [costEstimate, setCostEstimate] = useState(estimateInfo ? estimateInfo.costEstimate : 'na');
    const [reloPolicy, setReloPolicy] = useState(estimateInfo ? estimateInfo.reloPolicy : '');
    const [youngChildren, setYoungChildren] = useState(estimateInfo ? estimateInfo.youngChildren : '');
    const [oldChildren, setOldChildren] = useState(estimateInfo ? estimateInfo.oldChildren : '');
    const [accompanyingAdults, setAccompanyingAdults] = useState(estimateInfo ? estimateInfo.accompanyingAdults : '');
    const [origin, setOrigin] = useState(estimateInfo ? estimateInfo.origin : '');
    const [destination, setDestination] = useState(estimateInfo ? estimateInfo.destination : '');
    const [transfereeName, setTransfereeName] = useState(estimateInfo ? estimateInfo.transfereeName : '');
    const [distance, setDistance] = useState(0);

    const [perDiemRate, setPerDiemRate] = useState(0);
    const [avgHomesalePrice, setAvgHomesalePrice] = useState(0);
    const [avgHomesalePriceOrigin, setAvgHomesalePriceOrigin] = useState(0);
    const [flightCost, setFlightCost] = useState(0);
    const [isUpdatingSelections, setIsUpdatingSelections] = useState(false);
    const [homefindingDays, setHomefindingDays] = useState(3);

    const classes = useStyles();

    const exportEstimate = (savedBenefits, allBenefits) => {
        var content = 'Estimate Id, Transferee Name, Cost Estimate Policy, Relo Policy, Origin Location, Destination Location, Move Miles,\r\n';
        content = content + `${estimateId},${transfereeName},${costEstimate},${reloPolicy},${origin.formattedAddress ? origin.formattedAddress.split(',').join('') : ''},${destination.formattedAddress ? destination.formattedAddress.split(',').join('') : ''},${distance},\r\n\r\n`;

        content = content + 'Benefit Id, Benefit Name, Description, Total Cost, Option 1, Option 1 Cost, Option 2, Option 2 Cost, Option 3, Option 3 Cost, Option 4, Option 4 Cost, Option 5, Option 5 Cost, Option 6, Option 6 Cost, Option 7, Option 7 Cost, Option 8, Option 8 Cost,\r\n';
        allBenefits.map((b) => {
            let savedBenefit = savedBenefits.find((sb) => sb.benefitNumber === b.benefitNumber);
            if (savedBenefit) {
                const totalBenefitCost = b.options.reduce((total, o) => {
                    let savedOpt = savedBenefit.options.find((sbo) => sbo.optionNumber === o.optionNumber);
                    return total + (b.customTotal ? savedBenefit.cost : getOptionCost(o, savedOpt));
                }, 0);

                content = content + `${b.benefitNumber},${b.name},${b.secondaryText.split(',').join('')},${totalBenefitCost},`;

                b.options.map((bo) => {
                    let savedOpt = savedBenefit.options.find((sbo) => sbo.optionNumber === bo.optionNumber);
                    content = content + `${bo.description.split(',').join('')},${bo.customTotal ? savedBenefit.cost : getOptionCost(bo, savedOpt)},`;
                });

                content = content + '\r\n';
            }
        })
    
        var blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
        var url = URL.createObjectURL(blob);

        var pom = document.createElement('a');
        pom.href = url;
        pom.setAttribute('download', `Estimate-${estimateId}.csv`);
        pom.click();
    }

    const getOptionCost = (opt, selectedOption) => {
        if (!opt) return 0;
    
        if (opt.variable) {
          return distance * opt.multiplier;
        } else if (selectedOption && selectedOption.selectionId) {
          return opt.selections.find((selection) => selection.selectionId === selectedOption.selectionId).cost;
        } else {
          return opt.cost;
        }
    }

    useEffect(() => {
        (async () => {
            const perDiemSelectionId = estimateInfo?.savedBenefits ? estimateInfo.savedBenefits.find((sb) => sb.benefitNumber === 3)?.options.find((opt) => opt.optionNumber === 15)?.selectionId : null;
            const numDaysBetweenFlights = perDiemSelectionId ? (perDiemSelectionId === 8 ? 1 : (perDiemSelectionId === 9 ? 2 : (perDiemSelectionId === 11 ? 4 : 3))) : 3;
            setHomefindingDays(numDaysBetweenFlights);

            if (destination && destination.city && destination.state) {
                setIsUpdatingSelections(true);

                const gsaResp = await getGsaPerDiem(destination.city, destination.state, '2025');
                const gsaDestinationCity = gsaResp.rates[0].rate[0].city;
                const gsaDestinationState = gsaResp.rates[0].state;

                let regionName = 'United States';
                if (gsaDestinationCity !== null && gsaDestinationState !== null) {
                regionName = `${gsaDestinationCity}, ${gsaDestinationState}`;
                }

                const homesalePriceResp = await props.getHomesalePriceByRegion(regionName);
                if (homesalePriceResp.response) {
                setAvgHomesalePrice(parseFloat(homesalePriceResp.response.averagePrice.replace(/[$,]+/g,"")));
                }

                let currentDate = new Date();
                let fullRate = gsaResp.rates[0].rate[0].months.month.find((rt) => rt.number === (currentDate.getMonth() + 1));
                setPerDiemRate(fullRate.value * 1.2);

                if (origin && origin.city && origin.state) {
                const gsaOriginResp = await getGsaPerDiem(origin.city, origin.state, '2025');
                const gsaOriginCity = gsaOriginResp.rates[0].rate[0].city;
                const gsaOriginState = gsaOriginResp.rates[0].state;

                let originRegionName = 'United States';
                if (gsaOriginCity !== null && gsaOriginState !== null) {
                    originRegionName = `${gsaOriginCity}, ${gsaOriginState}`;
                }

                const homesalePriceOriginResp = await props.getHomesalePriceByRegion(originRegionName);
                if (homesalePriceOriginResp.response) {
                    setAvgHomesalePriceOrigin(parseFloat(homesalePriceOriginResp.response.averagePrice.replace(/[$,]+/g,"")));
                }

                const perDiemSelectionId = estimateInfo?.savedBenefits ? estimateInfo.savedBenefits.find((sb) => sb.benefitNumber === 3)?.options.find((opt) => opt.optionNumber === 15)?.selectionId : null;
                const numDaysBetweenFlights = perDiemSelectionId ? (perDiemSelectionId === 8 ? 1 : (perDiemSelectionId === 9 ? 2 : (perDiemSelectionId === 11 ? 4 : 3))) : 3;

                let baseDate = new Date();
                baseDate.setDate(baseDate.getDate() + 14);
                const departureDate = baseDate.toISOString().split('T')[0];
                baseDate.setDate(baseDate.getDate() + numDaysBetweenFlights);
                const returnDate = baseDate.toISOString().split('T')[0];

                const flightCostResp = await props.getFlightPrice({
                    departureCity: gsaOriginCity ? gsaOriginCity : origin.city,
                    departureState: gsaOriginState,
                    destinationCity: gsaDestinationCity ? gsaDestinationCity : destination.city,
                    destinationState: gsaDestinationState,
                    departureDate: departureDate,
                    returnDate: returnDate,
                });

                if (flightCostResp.response && flightCostResp.response.price) {
                    setFlightCost(parseFloat(flightCostResp.response.price.total.replace(/[$,]+/g,"")));
                }

                setIsUpdatingSelections(false);
                } else {
                setIsUpdatingSelections(false);
                }
            }
        })();
    }, [destination, origin]);

    useEffect(() => {
        (async () => {
            if (origin && origin.formattedAddress && destination && destination.formattedAddress) {
                let newDistance = await getDistance(origin, destination);
                setDistance(newDistance.value ? parseFloat(newDistance.value / 1609).toFixed(2) : 0);
            }
        })();
    }, []);

    const today = new Date();

    return (
        <div>
            {isUpdatingSelections && (
                <Box className={classes.loadingOverlay}>
                    <LogoSpinner size="large" />
                </Box>
            )}
            <Box sx={{ backgroundColor: 'white', padding: '10px 0px 10px 10px' }}>
                <Box>
                    <Grid container direction="row" >
                        <Grid item xs={2}>
                            <Box sx={{ paddingTop: '10px' }}>
                                <TextField
                                    id="name"
                                    className={classes.textField}
                                    label="Transferee Name"
                                    value={transfereeName}
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => setTransfereeName(e.target.value)}
                                    disabled={true}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{ paddingTop: '10px' }}>
                                <TextField
                                    id="costEstimate"
                                    select
                                    label="Cost Estimate Policy"
                                    className={classes.textField}
                                    onChange={(e) => setCostEstimate(e.target.value)}
                                    value={costEstimate}
                                    fullWidth={true}
                                    variant="outlined"
                                    size="small"
                                    disabled={true}
                                >
                                    <MenuItem value={'na'} key={1}>N/A</MenuItem>
                                    <MenuItem value={'tierOne'} key={2}>Tier One</MenuItem>
                                    <MenuItem value={'tierTwo'} key={3}>Tier Two</MenuItem>
                                    <MenuItem value={'custom'} key={4}>Custom</MenuItem>
                                </TextField>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{ paddingTop: '10px' }}>
                                <TextField
                                    id="costEstimate"
                                    select
                                    label="Relocation Policy"
                                    className={classes.textField}
                                    onChange={(e) => setReloPolicy(e.target.value)}
                                    value={reloPolicy}
                                    fullWidth={true}
                                    variant="outlined"
                                    size="small"
                                    disabled={true}
                                >
                                    <MenuItem value={'Homeowner'} key={0}>Homeowner</MenuItem>
                                    <MenuItem value={'Renter'} key={1}>Renter</MenuItem>
                                </TextField>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{ maxWidth: '200px' }}>
                                <GooglePlacesAutocompleteComponent
                                    initialValue={origin}
                                    value={origin.formattedAddress ? origin.formattedAddress : origin}
                                    label={'Origin Location'}
                                    onSelect={async (event, value) => {
                                        setOrigin(value);

                                        if (value && destination.length !== 0) {
                                            // Distance is returned in meters so we need to divide by 1609 to get miles.
                                            let newDistance = await getDistance(value, destination);
                                            setDistance(newDistance.value ? parseFloat(newDistance.value / 1609).toFixed(2) : 0);
                                        }
                                    }}
                                    onInputEdit={async (event) => {
                                        setOrigin(event);
                                    }}
                                    onInputClear={(event) => {
                                        setOrigin('');
                                    }}
                                    // onFocus={onFocus}
                                    // onBlur={onBlur}
                                    disabled={true}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{ maxWidth: '200px' }}>
                                <GooglePlacesAutocompleteComponent
                                    initialValue={destination}
                                    value={destination.formattedAddress ?  destination.formattedAddress : destination}
                                    label={'Destination Location'}
                                    onSelect={async (event, value) => {
                                        setDestination(value);
                                        if (value && origin.length !== 0) {
                                            // Distance is returned in meters so we need to divide by 1609 to get miles.
                                            let newDistance = await getDistance(origin, value);
                                            setDistance(newDistance.value ? parseFloat(newDistance.value / 1609).toFixed(2) : 0);
                                        }
                                    }}
                                    onInputEdit={(event) => setDestination(event)}
                                    onInputClear={(event) => {
                                        setDestination('');
                                    }}
                                    size="small"
                                    // onFocus={onFocus}
                                    // onBlur={onBlur}
                                    disabled={true}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{ paddingTop: '5px' }}>
                                <Typography color="primary">{estimateId ? `Cost Estimate ${estimateId}` : 'New Cost Estimate'}</Typography>
                                <Typography color="secondary">Move Miles: {distance}</Typography>
                            </Box>
                        </Grid>
                        {/* <Grid item xs={2}>
                            <Box sx={{ paddingTop: '0px' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isExecutive}
                                            onChange={(e) => setIsExecutive(!isExecutive)}
                                            size="small"
                                        />
                                    }
                                    label={'Is Executive'}
                                    disabled={true}
                                />
                            </Box>
                            <Box sx={{ paddingTop: '0px' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={hasSpouse}
                                            onChange={(e) => setHasSpouse(!hasSpouse)}
                                            size="small"
                                        />
                                    }
                                    label={'Traveling with SO'}
                                    disabled={true}
                                />
                            </Box>
                        </Grid> */}
                        <Grid item xs={2}>
                            <Box sx={{ paddingTop: '10px' }}>
                                <TextField
                                    id="youngChildren"
                                    className={classes.textField}
                                    label="# Children under 12"
                                    value={youngChildren}
                                    variant="outlined"
                                    type="number"
                                    onChange={(e) => setYoungChildren(e.target.value)}
                                    size="small"
                                    disabled={true}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{ paddingTop: '10px' }}>
                                <TextField
                                    id="oldChildren"
                                    className={classes.textField}
                                    label="# Children 13 to 17"
                                    value={oldChildren}
                                    variant="outlined"
                                    type="number"
                                    onChange={(e) => setOldChildren(e.target.value)}
                                    size="small"
                                    disabled={true}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{ paddingTop: '10px' }}>
                                <TextField
                                    id="accompanyingAdults"
                                    className={classes.textField}
                                    label="# Accompanying Adults"
                                    value={accompanyingAdults}
                                    variant="outlined"
                                    type="number"
                                    onChange={(e) => setAccompanyingAdults(e.target.value)}
                                    size="small"
                                    disabled={true}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={2}>
                            <Box sx={{ paddingTop: '10px' }}>
                                <TextField
                                    id="dateCreated"
                                    label="Date Created"
                                    type="date"
                                    value={`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate() <= 9 ? '0' + today.getDate() : today.getDate()}`}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        className: classes.input,
                                    }}
                                    placeholder={'yyyy-mm-dd'}
                                    className={classes.textField}
                                    disabled={true}
                                    size="small"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{ paddingTop: '10px' }}>
                                <TextField
                                    id="dateModified"
                                    label="Date Modified"
                                    type="date"
                                    value={`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate() <= 9 ? '0' + today.getDate() : today.getDate()}`}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        className: classes.input,
                                    }}
                                    placeholder={'yyyy-mm-dd'}
                                    className={classes.textField}
                                    disabled={true}
                                    size="small"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: '20px', paddingBottom: '20px'}}>
                    <Box sx={{paddingTop: '5px'}}>
                        <SelectionsCostBar savedBenefits={estimateInfo.savedBenefits} benefits={getBenefits(perDiemRate, avgHomesalePrice, avgHomesalePriceOrigin, flightCost, homefindingDays)} distance={distance} applyTaxGrossUp={estimateInfo.applyTaxGrossUp} />
                    </Box>
                    <Box sx={{ paddingLeft: '20px'}}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={estimateInfo.applyTaxGrossUp}
                                    // onChange={(e) => setApplyTaxGrossUp(!applyTaxGrossUp)}
                                    disabled={true}
                                    size="small"
                                />
                            }
                            label={'Apply Tax Gross Up (60%)'}
                        />
                    </Box>
                </Box>

                <Box sx={{ width: '100%', padding: '20px', paddingBottom: '40px' }}>
                    {estimateInfo.savedBenefits.map((sb) => {
                        let staticBenefit = getBenefits(perDiemRate, avgHomesalePrice, avgHomesalePriceOrigin, flightCost, homefindingDays).find((b) => b.benefitNumber === sb.benefitNumber);

                        let totalBenefitCost = 0;

                        if (staticBenefit.customTotal) {
                            if (sb) {
                                totalBenefitCost = sb.cost;
                            } else {
                                totalBenefitCost = 0;
                            }
                        } else {
                            if (sb) {
                                totalBenefitCost = staticBenefit.options.filter((opt) => sb.options.find((csbo) => csbo.optionNumber === opt.optionNumber)).reduce((total, o) => total + getOptionCost(o, sb.options.find((csbo) => csbo.optionNumber === o.optionNumber)), 0);
                            } else {
                                totalBenefitCost = staticBenefit.options.reduce((total, o) => total + (o.variable ? (props.distance * o.multiplier) : o.cost), 0);
                            }
                        }

                        return (
                            <Box>
                                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                    <Typography color="primary">{staticBenefit.name}</Typography>
                                    <Typography variant="subtitle1">{displayCost(totalBenefitCost, CostUnit.CURRENCY)}</Typography>
                                </Box>

                                <hr/>

                                <Box sx={{paddingBottom: '20px'}}>
                                    {sb.options.map((savedOpt) => {
                                        let staticOpt = staticBenefit.options.find((opt) => opt.optionNumber === savedOpt.optionNumber);
                                        if (staticOpt) {
                                            return (
                                                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                                    <Typography color="secondary">{staticBenefit.customTotal ? `${staticOpt.description} ${sb.isTaxable ? '(Taxable)' : '(Non Taxable)'}` : staticOpt.description} </Typography>
                                                    {!staticOpt.customTotal && (
                                                        <Typography variant="caption">{displayCost(getOptionCost(staticOpt, savedOpt), CostUnit.CURRENCY)}</Typography>
                                                    )}
                                                    {staticOpt.customTotal && (
                                                        <Typography variant="caption">{displayCost(Number(sb.cost), CostUnit.CURRENCY)}</Typography>
                                                    )}
                                                </Box>
                                            )
                                        }
                                    })}
                                </Box>
                            </Box>
                        )
                    })}
                </Box>

                {/* <BenefitSelections reloPolicy={reloPolicy} distance={distance} estimateInfo={estimateInfo} saveEstimate={saveEstimate} exportEstimate={exportEstimate} generateAuth={generateAuth}/> */}
            </Box>

            <Box sx={{ position: 'fixed', zIndex: 4, bottom: 0, backgroundColor: 'white', padding: '10px 0px 10px 10px', width: props.drawerOpen ? 'calc(100% - 240px)' : 'calc(100% - 60px)' }}>
                <Paper>
                    <Box p={2.5}>
                        <Grid container direction="row">
                            <Grid item xs={4}>
                                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                    <Typography variant="h6">Export Type</Typography>
                                </Box>        
                            </Grid>
                            <Grid item xs={4} container justify="center">

                            </Grid>
                            <Grid item xs={4} container justify="flex-end" alignItems="flex-start">
                                <Box sx={{marginTop: '10px'}}>
                                    <Button 
                                    size="small"
                                    color="secondary" 
                                    className={classes.button} 
                                    onClick={() => props.history.push(`/estimates/${props.match.params.estimateId}`)}
                                    >
                                        Back to Editor
                                    </Button>
                                    <Button 
                                        variant="contained" 
                                        size="small"
                                        color="secondary" 
                                        className={classes.button} 
                                        onClick={() => exportEstimate(estimateInfo.savedBenefits, getBenefits(perDiemRate, avgHomesalePrice, avgHomesalePriceOrigin, flightCost, homefindingDays))}
                                    >
                                        Export as PDF
                                    </Button>
                                    <Button 
                                        variant="contained" 
                                        size="small"
                                        color="primary" 
                                        className={classes.longButton} 
                                        onClick={() => exportEstimate(estimateInfo.savedBenefits, getBenefits(perDiemRate, avgHomesalePrice, avgHomesalePriceOrigin, flightCost, homefindingDays))}
                                    >
                                        Export as CSV
                                    </Button>
                                </Box>
                            
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Box>
        </div>
    );
  }
  
  const mapStateToProps = (state) => {
    return {
      authorizationSearchResults: state.transferees.get('authorizationSearchResults'),
      estimates: state.estimator.get('estimates'),
      drawerOpen: state.layout.get('drawerOpen'),
    };
  };
  
  export default withRouter(
    connect(mapStateToProps, {
      setPageTitle,
      showToast,
      setEstimations,
      getHomesalePriceByRegion,
      getFlightPrice,
    })(ExportPage),
  );
  