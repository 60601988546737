import HhgImage from './images/moving-truck.jpg';
import TempLivingImage from './images/temporary-living.jpg';
import FinalMoveImage from './images/airplane-travel.jpg';
import HomeFindingImage from './images/home-finding.jpeg';
import HomePurchasingImage from './images/home-purchasing.jpg';
import HomeSaleImage from './images/home-sale.jpg';
import RentalImage from './images/rental-image.jpg';
import SpousalImage from './images/spousal-assistance.jpg';
import GeneralRelocationImage from './images/general-relocation.jpg';

export const getBenefits = (perDiemCost, avgHomesalePrice, avgHomesalePriceOrigin, flightCost, homefindingDays) => [
    {
        name: 'Household Goods',
        secondaryText: 'Shipment of your household goods, vehicles, and pets.',
        generalBenefitHelp: 'The household goods service consist of the transportation of your household goods, vehicles, and pets.',
        imageUrl: HhgImage,
        benefitNumber: 0,
        isSelected: false,
        topLevelInputOne: {
            name: 'Property Type',
            id: 'propertyType',
            options: [
                { id: 1, label: 'Studio Apt.' },
                { id: 2, label: 'Apartment' },
                { id: 3, label: 'Condo/ Townhouse' },
                { id: 4, label: 'House' }
            ],
        },
        topLevelInputTwo: {
            name: 'Number of Bedrooms',
            id: 'numBedrooms',
            options: [
                { id: 1, label: '1 Bedroom' },
                { id: 2, label: '2 Bedrooms' },
                { id: 3, label: '3 Bedrooms' },
                { id: 4, label: '4+ Bedrooms' }
            ],
        },
        options: [
            {
                description: 'Full pack, load, transport.',
                cost: 0,
                optionNumber: 1,
                helpIntentId: 'pack_load_hint_v1'
            },
            {
                description: 'Auto Shipment.',
                variable: 'miles',
                multiplier: 5,
                optionNumber: 2,
                helpIntentId: 'auto_shipment_hint_v1'
            },
            // {
            //     description: 'Partial unpack.',
            //     cost: 100,
            //     optionNumber: 3
            // },
            // {
            //     description: 'Normal appliance servicing.',
            //     cost: 100,
            //     optionNumber: 4
            // },
            // {
            //     description: 'Crating.',
            //     cost: 100,
            //     optionNumber: 5
            // },
            {
                description: 'Storage.',
                cost: 0,
                optionNumber: 6,
                helpIntentId: 'storage_hint_v1'
            },
            // {
            //     description: 'Insurance.',
            //     cost: 100,
            //     optionNumber: 7
            // },
            // {
            //     description: 'Discard and donate.',
            //     cost: 0,
            //     optionNumber: 8
            // },
        ]
    },
    {
        name: 'Rental',
        secondaryText: 'Costs associated with a rental relocation.',
        generalBenefitHelp: 'Common costs associated with a rental relocation.',
        imageUrl: RentalImage,
        benefitNumber: 1,
        isSelected: false,
        isRenterOnly: true,
        options: [
            {
                description: 'Rental tour fees.',
                cost: 795,
                optionNumber: 9,
                helpIntentId: 'rental_tour_hint_v1'
            },
            {
                description: 'Lease cancellation.',
                cost: 1945.05,
                optionNumber: 10,
                helpIntentId: 'lease_cancellation_hint_v1'
            },
        ]
    },
    {
        name: 'Temporary Living',
        secondaryText: 'Temporary housing in your new location.',
        generalBenefitHelp: 'Common costs associated with temporary living in the destination location.',
        imageUrl: TempLivingImage,
        benefitNumber: 2,
        isSelected: false,
        options: [
            {
                description: 'Days in temp living.',
                cost: 6000,
                optionNumber: 11,
                helpIntentId: 'temp_living_days_hint_v1',
                defaultSelectionId: 1,
                selections: [{
                  selectionId: 1,
                  text: 'Up to 30 days.',
                  cost: 3000
                }, {
                  selectionId: 2,
                  text: 'Up to 60 days.',
                  cost: 6000
                }, {
                  selectionId: 3,
                  text: 'Up to 90 days.',
                  cost: 9000
                }]
            },
            {
                description: 'Return trips.',
                cost: flightCost,
                optionNumber: 12,
                helpIntentId: 'return_trip_hint_v1',
            },
            {
                description: 'Rental car for 10 days or until personal car is delivered.',
                cost: 98.89 * 10 * .7, // Using 70% of total considering the price per day goes down for longer bookings.
                helpIntentId: 'long_term_car_rental_hint_v1',
                optionNumber: 13
            },
        ]
    },
    {
        name: 'Home Finding',
        secondaryText: 'Support for new home finding trips.',
        generalBenefitHelp: 'Common costs associated with support for new home finding trips.',
        imageUrl: HomeFindingImage,
        benefitNumber: 3,
        isSelected: false,
        options: [
            {
                description: 'Airfare / mileage / tolls.',
                cost: flightCost,
                helpIntentId: 'airfare_hint_v1',
                optionNumber: 14
            },
            {
                description: 'Per diem for lodging, meals, and incidentals.',
                cost: perDiemCost,
                optionNumber: 15,
                helpIntentId: 'per_diem_hint_v1',
                defaultSelectionId: 10,
                selections: [{
                    selectionId: 8,
                    text: '1 Day.',
                    cost: perDiemCost
                }, {
                    selectionId: 9,
                    text: '2 Days.',
                    cost: perDiemCost * 2
                }, {
                    selectionId: 10,
                    text: '3 Days.',
                    cost: perDiemCost * 3
                }, {
                    selectionId: 11,
                    text: '4 Days.',
                    cost: perDiemCost * 4
                }]
            },
            {
                description: 'Car rental.',
                cost: 98.89 * homefindingDays,
                helpIntentId: 'car_rental_hint_v1',
                optionNumber: 17
            },
            {
                description: 'Transportation to/from airport or airport parking if applicable.',
                cost: 61.71,
                helpIntentId: 'airport_parking_hint_v1',
                optionNumber: 18
            },
        ]
    },
    {
        name: 'Home Purchasing',
        secondaryText: 'Support for new home purchasing.',
        generalBenefitHelp: 'The home purchasing benefit assists the transferee with general costs of purchasing a home.',
        imageUrl: HomePurchasingImage,
        benefitNumber: 4,
        isSelected: false,
        isOwnerOnly: true,
        options: [
            {
                description: 'Direct bill / reimbursement on 2% of mortgage.',
                cost: avgHomesalePrice * .02,
                optionNumber: 19,
                helpIntentId: 'direct_bill_hint_v1'
            },
            // {
            //     description: 'Referrals to preferred agents.',
            //     cost: 0,
            //     optionNumber: 20,
            //     helpIntentId: 'referrals_hint_v1'
            // },
        ]
    },
    {
        name: 'Home Sale',
        secondaryText: 'Assistance with home sale.',
        generalBenefitHelp: 'The home sale benefit assists the transferee with general costs of selling a home.',
        imageUrl: HomeSaleImage,
        benefitNumber: 5,
        isSelected: false,
        isOwnerOnly: true,
        options: [
            {
                description: 'Direct reimbursement - typically calculated as 8% of the sale price as closing costs.',
                cost: avgHomesalePriceOrigin * .08,
                optionNumber: 21,
                helpIntentId: 'direct_reimbursment_hint_v1'
            },
        ]
    },
    {
        name: 'Spouse / Partner Assistance',
        secondaryText: 'Assistance for the spouse / partner in the new location.',
        generalBenefitHelp: 'The spouse / partner assistance benefit provides help for the spouse / partner in the new location.',
        imageUrl: SpousalImage,
        benefitNumber: 6,
        isSelected: false,
        options: [
            {
                description: 'Courses, professional liscensing, resume support, etc.',
                cost: 1250,
                optionNumber: 22,
                helpIntentId: 'spouse_assistance_hint_v1'
            },
        ]
    },
    {
        name: 'Relocation Allowance',
        secondaryText: 'Custom allowance for general relocation costs.',
        imageUrl: GeneralRelocationImage,
        benefitNumber: 8,
        isSelected: false,
        customTotal: true,
        options: [
            {
                description: 'Allowance for miscellaneous benefits.',
                customTotal: true,
                optionNumber: 27
            },
        ]
    },
    {
        name: 'Final Move',
        secondaryText: 'Costs associated with the final trip.',
        generalBenefitHelp: 'Common costs associated with support for a final trip.',
        imageUrl: FinalMoveImage,
        benefitNumber: 7,
        isSelected: false,
        options: [
            {
                description: 'Airfare, mileage, tolls, etc.',
                cost: flightCost ? parseFloat(flightCost) / 2 : 0,
                helpIntentId: 'airfare_oneway_hint_v1',
                optionNumber: 23
            },
            {
                description: 'Per diem for lodging, meals, and incidentals.',
                cost: perDiemCost,
                optionNumber: 24,
                helpIntentId: 'per_diem_hint_v1',
                defaultSelectionId: 6,
                selections: [{
                    selectionId: 4,
                    text: '1 Day.',
                    cost: perDiemCost
                }, {
                    selectionId: 5,
                    text: '2 Days.',
                    cost: perDiemCost * 2
                }, {
                    selectionId: 6,
                    text: '3 Days.',
                    cost: perDiemCost * 3
                }, {
                    selectionId: 7,
                    text: '4 Days.',
                    cost: perDiemCost * 4
                }]
            },
            {
                description: 'Transportation to/from airport.',
                cost: 33.68,
                helpIntentId: 'airport_transportation_hint_v1',
                optionNumber: 26
            },
        ]
    },
]