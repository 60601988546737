/* eslint-disable array-callback-return */
import { Box, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import { CostUnit, displayCost } from 'utilities/currencyFormatter';

const SelectionsCostBar = (props) => {
    let total = 0;
    props.benefits.filter((b) => props.savedBenefits.find((sb) => sb.benefitNumber === b.benefitNumber)).map((benefit) => {
        let currentSavedBenefit = props.savedBenefits.find((sb) => sb.benefitNumber === benefit.benefitNumber);

        benefit.options.map((opt) => {
            let currentSavedOption = currentSavedBenefit.options.find((csbo) => csbo.optionNumber === opt.optionNumber);

            if (currentSavedOption) {
                if (opt.customTotal) {
                    total = total + currentSavedBenefit.cost;
                } else if (opt.variable) {
                    total = total + (props.distance * opt.multiplier);
                } else if (opt.selections && currentSavedOption.selectionId) {
                    total = total + opt.selections.find((selection) => selection.selectionId === currentSavedOption.selectionId).cost;
                } else {
                    total = total + opt.cost;
                }
            }
        })
    });

    if (props.applyTaxGrossUp) {
        let reloAllowanceBen = props.savedBenefits.find((sb) => sb.benefitNumber === 8);
        if (reloAllowanceBen && !reloAllowanceBen.isTaxable) {
            let taxableTotal = total - reloAllowanceBen.cost;
            total = (taxableTotal * 1.6) + reloAllowanceBen.cost;
        } else {
            total = total * 1.6;
        }
    }

    return (
      <Fragment>
        <Box position="relative" width="100%">
            <Typography sx={{ color: '#4d8766'}} color="primary" variant="h5">
                Estimate: {displayCost(total, CostUnit.CURRENCY)}
            </Typography>
        </Box>
      </Fragment>
    );
  };

export default SelectionsCostBar;

