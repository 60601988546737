import { CALL_SELF_SERVE_API } from "middleware/selfServeApi";

export const SET_NEW_ESTIMATION = 'SET_NEW_ESTIMATION';

export const GET_ESTIMATES_REQUEST = 'GET_ESTIMATES_REQUEST';
export const GET_ESTIMATES_SUCCESS = 'GET_ESTIMATES_SUCCESS';
export const GET_ESTIMATES_FAILURE = 'GET_ESTIMATES_FAILURE';

export const POST_ESTIMATE_REQUEST = 'POST_ESTIMATE_REQUEST';
export const POST_ESTIMATE_SUCCESS = 'POST_ESTIMATE_SUCCESS';
export const POST_ESTIMATE_FAILURE = 'POST_ESTIMATE_FAILURE';

export const UPDATE_ESTMATE_REQUEST = 'UPDATE_ESTMATE_REQUEST';
export const UPDATE_ESTIMATE_SUCCESS = 'UPDATE_ESTIMATE_SUCCESS';
export const UPDATE_ESTIMATE_FAILURE = 'UPDATE_ESTIMATE_FAILURE';

export const DELETE_ESTIMATE_REQUEST = 'DELETE_ESTIMATE_REQUEST';
export const DELETE_ESTIMATE_SUCCESS = 'DELETE_ESTIMATE_SUCCESS';
export const DELETE_ESTIMATE_FAILURE = 'DELETE_ESTIMATE_FAILURE';

export const GET_HOMESALE_PRICE_REQUEST = 'GET_HOMESALE_PRICE_REQUEST';
export const GET_HOMESALE_PRICE_SUCCESS = 'GET_HOMESALE_PRICE_SUCCESS';
export const GET_HOMESALE_PRICE_FAILURE = 'GET_HOMESALE_PRICE_FAILURE';

export const GET_FLIGHT_PRICE_REQUEST = 'GET_FLIGHT_PRICE_REQUEST';
export const GET_FLIGHT_PRICE_SUCCESS = 'GET_FLIGHT_PRICE_SUCCESS';
export const GET_FLIGHT_PRICE_FAILURE = 'GET_FLIGHT_PRICE_FAILURE';

export const setEstimations = (estimationList) => {
  return {
    type: SET_NEW_ESTIMATION,
    payload: estimationList,
  };
};

export const getEstimates = () => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [GET_ESTIMATES_REQUEST, GET_ESTIMATES_SUCCESS, GET_ESTIMATES_FAILURE],
      authenticated: true,
      endpoint: 'estimates',
      method: 'GET',
    },
  };
};

export const postEstimate = (estimateData) => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [POST_ESTIMATE_REQUEST, POST_ESTIMATE_SUCCESS, POST_ESTIMATE_FAILURE],
      authenticated: true,
      endpoint: 'estimate',
      method: 'POST',
      payload: estimateData,
    },
  };
};

export const updateEstimate = (estimateData) => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [UPDATE_ESTMATE_REQUEST, UPDATE_ESTIMATE_SUCCESS, UPDATE_ESTIMATE_FAILURE],
      authenticated: true,
      endpoint: 'estimate',
      method: 'PUT',
      payload: estimateData,
    },
  };
};

export const deleteEstimate = (estimateId) => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [DELETE_ESTIMATE_REQUEST, DELETE_ESTIMATE_SUCCESS, DELETE_ESTIMATE_FAILURE],
      authenticated: true,
      endpoint: `estimate/${estimateId}`,
      method: 'DELETE',
    },
  };
};

export const getHomesalePriceByRegion = (regionName) => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [GET_HOMESALE_PRICE_REQUEST, GET_HOMESALE_PRICE_SUCCESS, GET_HOMESALE_PRICE_FAILURE],
      authenticated: true,
      endpoint: `homesalePrices/${regionName}`,
      method: 'GET',
    },
  };
};

export const getFlightPrice = (payload) => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [GET_FLIGHT_PRICE_REQUEST, GET_FLIGHT_PRICE_SUCCESS, GET_FLIGHT_PRICE_FAILURE],
      authenticated: true,
      endpoint: 'flightPrice',
      method: 'POST',
      payload: payload
    },
  };
};
