/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import React, { useEffect } from 'react';
  
import { getEstimates } from './estimator.actions';
import CostEstimatorPage from './CostEstimatorPage.component';
import FullscreenSpinner from 'common/fullscreenSpinner.component';
import { setBackButtonPath, setPageTitle } from 'modules/layout/layout.actions';
  
  const CostEstimator = (props) => {  

    useEffect(() => {
        (async () => {
            props.setPageTitle('Cost Estimator');
            props.setBackButtonPath('/estimates');
            await props.getEstimates();
        })();
    }, []);

    return (
        <>
            {props.estimates && <CostEstimatorPage history={props.history}/>}
            {!props.estimates && <FullscreenSpinner/>}
        </>
    );
  }
  
  const mapStateToProps = (state) => {
    return {
      estimates: state.estimator.get('estimates'),
    };
  };
  
  export default withRouter(
    connect(mapStateToProps, {
      getEstimates,
      setPageTitle,
      setBackButtonPath,
    })(CostEstimator),
  );
  